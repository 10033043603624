import * as Sentry from '@sentry/browser'

const sentry = (store) => {
    Sentry.configureScope((scope) => {
        scope.addEventProcessor((event) => {
            const { ...state } = store.getState()

            return {
                ...event,
                extra: {
                    ...event.extra,
                    'redux:state': state
                }
            }
        })
    })

    return next => (action) => {
        Sentry.addBreadcrumb({
            category: 'redux-action',
            message: action.type
        })

        return next(action)
    }
}

export default sentry