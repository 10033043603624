import { withStyles } from '@material-ui/core/styles'
import { setDisplayName } from 'recompose'
import styles from './NotFoundPage.styles'
import { compose } from 'recompose'
import { UserIsAuthenticated } from 'utils/router'

export default compose(
  setDisplayName('NotFoundPage'),
  UserIsAuthenticated,
  withStyles(styles)
)
