import { Loadable } from 'utils/components'
import { EMOTION_EDIT_FULL_PATH as path } from 'constants/paths'

export default {
  path,
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: 'EmotionEditFullPathPage' */ './components/EmotionEditFullPathPage')
  })
}
