import React from 'react'
import PropTypes from 'prop-types'
import lottie from 'lottie-web'
import animation from '../../static/animations/loader.json'

export class LoadingSpinner extends React.Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: this.el,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation
    })
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.progress}>
          <div
            ref={c => {
              this.el = c
            }}
          />
        </div>
      </div>
    )
  }
}

LoadingSpinner.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.number
}

export default LoadingSpinner
