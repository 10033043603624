import React from "react";
import PropTypes from "prop-types";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import {
  EMOTION_LIST_PATH,
  PLAN_RESPONSE_PATH,
  EMOTION_SELECT_TYPE_PATH
} from "constants/paths";
import EmotionIcon from "./../../../../static/home_emotion.png";
import ReactionIcon from "./../../../../static/home_reaction.png";
import ListIcon from "./../../../../static/home_list.png";
import LogoFace from "./../../../../static/logo_face.png";
import { Typography } from "@material-ui/core";

const Home = ({ profile, classes, t }) => (
  <div className={classes.root}>
    <div className={classes.root}>
      <div className={classes.center}>
        <div className={classes.header}>
          <img src={LogoFace} alt="logo face" className={classes.headerImage} />
          <Typography className={classes.headerText}>
            {t("hello")} {profile.firstName}
          </Typography>
        </div>
        <div className={classes.buttonItem}>
          <ButtonBase
            className={classes.image}
            component={Link}
            to={EMOTION_SELECT_TYPE_PATH}
          >
            <span
              className={[classes.imageSrc]}
              style={{
                backgroundImage: `url(${EmotionIcon})`
              }}
            />
            <span className={classes.imageBackdrop} />
          </ButtonBase>
          <span className={classes.imageText}>
            <div>
              {t("addEmotion")}
              <b>{t("addEmotionBold")}</b>
            </div>
          </span>
        </div>
        <div className={classes.buttonItem}>
          <ButtonBase
            className={classes.image}
            component={Link}
            to={PLAN_RESPONSE_PATH}
          >
            <span
              className={[classes.imageSrc]}
              style={{
                backgroundImage: `url(${ReactionIcon})`
              }}
            />
            <span className={classes.imageBackdrop} />
          </ButtonBase>
          <span className={classes.imageText}>
            <div>
              {t("planEmotion")}
              <b>{t("planEmotionBold")}</b>
            </div>
          </span>
        </div>
        <div className={classes.buttonItem}>
          <ButtonBase
            className={classes.image}
            component={Link}
            to={EMOTION_LIST_PATH}
          >
            <span
              className={[classes.imageSrc]}
              style={{
                backgroundImage: `url(${ListIcon})`
              }}
            />
            <span className={classes.imageBackdrop} />
          </ButtonBase>
          <span className={classes.imageText}>
            <div>
              {t("listEmotion")}
              <b>{t("listEmotionBold")}</b>
            </div>
          </span>
        </div>
      </div>

      {/*
      <div className={classes.section}>
        <h3>Routing</h3>
        <span>
          Redirecting and route protection done using:
          <div>
            <span>
              <a
                href={reactRouterUrl}
                target="_blank"
                rel="noopener noreferrer">
                react-router
              </a>
            </span>
            <span> and </span>
            <a href={authWrapperUrl} target="_blank" rel="noopener noreferrer">
              redux-auth-wrapper
            </a>
          </div>
        </span>
      </div>
      <div className={classes.section}>
        <h4>Logged Out</h4>
        <span>
          User is redirected to <pre>/login</pre> if not authenticated and
          trying to vist:
        </span>
        <ul>
          <li>
            <Link to={LIST_PATH}>Projects</Link>
          </li>
          <li>
            <Link to={ACCOUNT_PATH}>Account</Link>
          </li>
        </ul>
      </div>
      <div className={classes.section}>
        <h4>Logged In</h4>
        <span>
          User is redirected to <pre>/projects</pre> if authenticated and trying
          to vist:
        </span>
        <ul>
          <li>
            <Link to={LOGIN_PATH}>Login</Link>
          </li>
          <li>
            <Link to={SIGNUP_PATH}>Signup</Link>
          </li>
        </ul>
      </div>
      <div className={classes.section}>
        <div>
          <h4>Forms</h4>
          <span>Redirecting and route protection done using:</span>
          <div>
            <span>
              <a
                href={reactRouterUrl}
                target="_blank"
                rel="noopener noreferrer">
                redux-form
              </a>
            </span>
          </div>
        </div>
        <span>The following routes use redux-form:</span>
        <Link to={ACCOUNT_PATH}>
          <p>Account Page</p>
        </Link>
      </div>
            */}
    </div>
  </div>
);

Home.proptypes = {
  classes: PropTypes.object.isRequired // from enhancer (withStyles)
};

export default withNamespaces("homePage")(Home);
