import { Loadable } from 'utils/components'
import { EMOTION_NEW_PATH as path } from 'constants/paths'

export default {
  path,
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: 'EmotionsAdd' */ './components/EmotionAdd')
  })
}
