export default theme => ({
  root: {
    ...theme.flexColumnCenter,
    height: '100%'
  },
  section: {
    ...theme.flexColumnCenter
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-evenly'
  },
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '0px 10px'
  },
  headerText: {
    marginLeft: '10px',
    fontSize: theme.typography.pxToRem(17)
  },
  headerImage: {
    height: '60px'
  },
  buttonItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  image: {
    width: '125px',
    height: '125px',
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15
      }
    },
    marginBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      height: '100px'
    }
  },
  focusVisible: {},
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%'
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#f4f4f4',
    opacity: 0.25,
    transition: theme.transitions.create('opacity')
  },
  imageText: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px'
    }
  }
})
