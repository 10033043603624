export default theme => ({
  root: {
    margin: '2% 10%',
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    padding: 20
  },
  title: {
    textAlign: 'center'
  },
  image: {
    position: 'absolute',
    width: '50px',
    height: '33px',
    right: '30px',
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15
      }
    }
  },
  focusVisible: {},
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%'
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    opacity: 0.4,
    transition: theme.transitions.create('opacity')
  }
})
