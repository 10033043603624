import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import ButtonBase from '@material-ui/core/ButtonBase'
import { LEGAL_NOTICE_EN_PATH } from 'constants/paths'
import { Link } from 'react-router-dom'
import EnglishFlag from './../../../../../static/flag_united_kingdom.png'

const LegalNoticePage = ({ classes }) => (
  <div className={classes.root}>
    <div>
      <ButtonBase
        component={Link}
        to={LEGAL_NOTICE_EN_PATH}
        className={classes.image}>
        <span
          className={[classes.imageSrc]}
          style={{
            backgroundImage: `url(${EnglishFlag})`
          }}
        />
        <span className={classes.imageBackdrop} />
      </ButtonBase>
    </div>
    <Paper className={classes.paper}>
      <h2 className={classes.title}>Mentions Légales</h2>
      <p>
        Informations légales :{' '}
        <a href="https://www.lfeapp.com">https://www.lfeapp.com</a>
      </p>
      <br />
      <p>Siège social : </p>
      <p>Learning and Development Business Partners S.A.S. (L&D BP)</p>
      <p>67 rue Saint Jacques</p>
      <p>75005 Paris, France</p>
      <br />
      <p>Email : contact@lfeapp.com </p>
      <p>Téléphone : +33 (0)1 49 11 46 65</p>
      <br />
      <p>
        Numéro d'immatriculation auprès du Registre de Commerce et des Sociétés
        de Paris : B 832 724 272
      </p>
      <p>Numéro d'identification à la TVA : FR 05 832724272</p>
      <br />
      <p>
        Site réalisé par : Learning and Development Business Partners S.A.S.
      </p>
      <p>Responsable de publication : Pierre de Gentile, contact@lfeapp.com</p>
      <br />
      <p>Hébergeurs :</p>
      <p>1&1 IONOS SARL France</p>
      <p>Firebase, serveurs situés en France régis par la loi française</p>
    </Paper>
  </div>
)

LegalNoticePage.propTypes = {
  classes: PropTypes.object // from enhancer (withStyles)
}

export default LegalNoticePage
