import { compose, withHandlers, setDisplayName } from 'recompose'
import { withStore } from 'utils/components'
import * as actions from '../actions'

const withSnackbars = compose(
  setDisplayName('withSnackbars'),
  withStore,
  withHandlers({
    showSnackbarError: ({ store }) => err =>
      actions.showSnackbarError(err)(store.dispatch),
    showSnackbarSuccess: ({ store }) => err =>
      actions.showSnackbarSuccess(err)(store.dispatch),
    showSnackbarWarning: ({ store }) => err =>
      actions.showSnackbarWarning(err)(store.dispatch),
    showSnackbarInfo: ({ store }) => err =>
      actions.showSnackbarInfo(err)(store.dispatch),
    dismissSnackbar: ({ store }) => id =>
      actions.dismissSnackbar(id)(store.dispatch)
  })
)

export default withSnackbars
