import { combineReducers } from 'redux'
import { without, omit } from 'lodash'
import { SNACKBAR_SHOW, SNACKBAR_DISMISS } from './actionTypes'

const snackbar = (state = {}, action) => {
    switch (action.type) {
        case SNACKBAR_SHOW:
            return action.payload
        case SNACKBAR_DISMISS:
            return undefined
        default:
            return state
    }
}

const allIds = (state = [], action) => {
    switch (action.type) {
        case SNACKBAR_SHOW:
            return [...state, action.payload.id]
        case SNACKBAR_DISMISS:
            return without(state, action.payload)
        default:
            return state
    }
}

const byId = (state = {}, action) => {
    switch (action.type) {
        case SNACKBAR_SHOW:
            return {
                ...state,
                [action.payload.id]: snackbar(state[action.payload.id], action)
            }
        case SNACKBAR_DISMISS:
            return omit(state, action.payload)
        default:
            return state
    }
}

export const snackbars = combineReducers({ byId, allIds })

export default snackbars
