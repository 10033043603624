export const version = "1.0.2";

export const env = "production";

export const firebase = {
  apiKey: "AIzaSyD_tXl3xLxLkp3LTTJfRsgtM7e4fx3dwUw",
  authDomain: "https://lfe-app.firebaseio.com",
  databaseURL: "https://lfe-app.firebaseio.com",
  projectId: "lfe-app",
  storageBucket: "lfe-app.appspot.com",
};

export const sentryDsn = "https://d312d662d7ab40a59f16a22e129c94fd@sentry.io/1436082";

export const analyticsTrackingId = "UA-138296063-1";

export const reduxFirebase = {
  enableLogging: "false",
};

export default { version, env, firebase, sentryDsn, analyticsTrackingId, reduxFirebase }