import React from 'react'
import PropTypes from 'prop-types'
import BottomBar from 'containers/BottomBar'
import ErrorBoundary from 'utils/errorBoundary'

export const AuthLayout = ({ children, classes }) => {
  return (
    <div className={classes.container}>
      <ErrorBoundary className={classes.children}>
        <div className={classes.children}>{children}</div>
      </ErrorBoundary>
      <BottomBar />
    </div>
  )
}

AuthLayout.propTypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles)
  children: PropTypes.element.isRequired
}

export default AuthLayout
