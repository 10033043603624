import { SNACKBAR_SHOW, SNACKBAR_DISMISS, SNACKBAR_CLEAR } from './actionTypes'

const defaultDismissTime = 2500 // 2.5 seconds

/**
 * Publish a snackbar. if `dismissAfter` is set, the snackbar will be
 * auto dismissed after the given period.
 * @param {Object} notif - Object containing
 * @param {Object} notif.kind - Kinda of snackbar (success, warning, failure)
 * @param {Object} notif.message - snackbar message
 * @param {Object} notif.dismissAfter - Time after which to dismiss snackbar (default time set in constants)
 */
export function showSnackbar(notif) {
  const payload = Object.assign({}, notif)
  // Set default id to now if none provided
  if (!payload.id) {
    payload.id = Date.now()
  }
  return dispatch => {
    dispatch({ type: SNACKBAR_SHOW, payload })

    setTimeout(() => {
      dispatch({
        type: SNACKBAR_DISMISS,
        payload: payload.id
      })
    }, payload.dismissAfter || defaultDismissTime)
  }
}

/**
 * Show message for a success
 * @param {String} message - Message to show
 */
export function showSnackbarSuccess(message) {
  return showSnackbar({ type: 'success', message })
}

/**
 * Show message for a error
 * @param {String} message - Message to show
 */
export function showSnackbarError(message) {
  return showSnackbar({ type: 'error', message: `${message || ''}` })
}

/**
 * Show message for a warning
 * @param {String} message - Message to show
 */
export function showSnackbarWarning(message) {
  return showSnackbar({ type: 'warning', message: `${message || ''}` })
}

/**
 * Show message for a info
 * @param {String} message - Message to show
 */
export function showSnackbarInfo(message) {
  return showSnackbar({ type: 'info', message: `${message || ''}` })
}

/**
 * Dismiss a snackbar by the given id.
 * @param {Number} id - snackbar id
 */
export function dismissSnackbar(payload) {
  return {
    type: SNACKBAR_DISMISS,
    payload
  }
}

/**
 * Clear all snackbar
 */
export function clearSnackbar() {
  return { type: SNACKBAR_CLEAR }
}
