import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import detector from "i18next-browser-languagedetector";
//import backend from 'i18next-xhr-backend'

// EN
import ENaccountPage from "locales/en/accountPage.json";
import ENdialogAffectedByMyEmotion from "locales/en/dialogAffectedByMyEmotion.json";
import ENdialogDeleteEmotion from "locales/en/dialogDeleteEmotion.json";
import ENdialogDeleteEntries from "locales/en/dialogDeleteEntries.json";
import ENdialogDeletePlanResponse from "locales/en/dialogDeletePlanResponse.json";
import ENdialogEditEntries from "locales/en/dialogEditEntries.json";
import ENdialogEditPlanResponse from "locales/en/dialogEditPlanResponse.json";
import ENdialogEmotionAllowedMeToAdd from "locales/en/dialogEmotionAllowedMeToAdd.json";
import ENdialogFactAdd from "locales/en/dialogFactAdd.json";
import ENdialogNewPlanResponse from "locales/en/dialogNewPlanResponse.json";
import ENdialogPerceptionOriginAdd from "locales/en/dialogPerceptionOriginAdd.json";
import ENdialogPerceptionThinkingAdd from "locales/en/dialogPerceptionThinkingAdd.json";
import ENdialogPerceptionWitnessAdd from "locales/en/dialogPerceptionWitnessAdd.json";
import ENdialogPersonPresentAdd from "locales/en/dialogPersonPresentAdd.json";
import ENdialogPlaceAdd from "locales/en/dialogPlaceAdd.json";
import ENdialogTitleAdd from "locales/en/dialogTitleAdd.json";
import ENemotionAdd from "locales/en/emotionAdd.json";
import ENemotionList from "locales/en/emotionList.json";
import ENemotionType from "locales/en/emotionType.json";
import ENerrorBoundary from "locales/en/errorBoundary.json";
import ENhomePage from "locales/en/homePage.json";
import ENinformationPage from "locales/en/informationPage.json";
import ENinformationFailure from "locales/en/informationFailure.json";
import ENinformationGuilt from "locales/en/informationGuilt.json";
import ENinformationLossAndTrauma from "locales/en/informationLossAndTrauma.json";
import ENinformationLowSelfEsteem from "locales/en/informationLowSelfEsteem.json";
import ENinformationRejection from "locales/en/informationRejection.json";
import ENinformationRumination from "locales/en/informationRumination.json";
import ENinformationSolitude from "locales/en/informationSolitude.json";
import ENpasswordLostPage from "locales/en/passwordLostPage.json";
import ENpasswordResetFormPage from "locales/en/passwordResetFormPage.json";
import ENpasswordResetPage from "locales/en/passwordResetPage.json";
import ENplanResponse from "locales/en/planResponse.json";
import ENstatisticPage from "locales/en/statisticPage.json";
// FR
import FRaccountPage from "locales/fr/accountPage.json";
import FRdialogAffectedByMyEmotion from "locales/fr/dialogAffectedByMyEmotion.json";
import FRdialogDeleteEmotion from "locales/fr/dialogDeleteEmotion.json";
import FRdialogDeleteEntries from "locales/fr/dialogDeleteEntries.json";
import FRdialogDeletePlanResponse from "locales/fr/dialogDeletePlanResponse.json";
import FRdialogEditEntries from "locales/fr/dialogEditEntries.json";
import FRdialogEditPlanResponse from "locales/fr/dialogEditPlanResponse.json";
import FRdialogEmotionAllowedMeToAdd from "locales/fr/dialogEmotionAllowedMeToAdd.json";
import FRdialogFactAdd from "locales/fr/dialogFactAdd.json";
import FRdialogNewPlanResponse from "locales/fr/dialogNewPlanResponse.json";
import FRdialogPerceptionOriginAdd from "locales/fr/dialogPerceptionOriginAdd.json";
import FRdialogPerceptionThinkingAdd from "locales/fr/dialogPerceptionThinkingAdd.json";
import FRdialogPerceptionWitnessAdd from "locales/fr/dialogPerceptionWitnessAdd.json";
import FRdialogPersonPresentAdd from "locales/fr/dialogPersonPresentAdd.json";
import FRdialogPlaceAdd from "locales/fr/dialogPlaceAdd.json";
import FRdialogTitleAdd from "locales/fr/dialogTitleAdd.json";
import FRemotionAdd from "locales/fr/emotionAdd.json";
import FRemotionList from "locales/fr/emotionList.json";
import FRemotionType from "locales/fr/emotionType.json";
import FRerrorBoundary from "locales/fr/errorBoundary.json";
import FRhomePage from "locales/fr/homePage.json";
import FRinformationPage from "locales/fr/informationPage.json";
import FRinformationFailure from "locales/fr/informationFailure.json";
import FRinformationGuilt from "locales/fr/informationGuilt.json";
import FRinformationLossAndTrauma from "locales/fr/informationLossAndTrauma.json";
import FRinformationLowSelfEsteem from "locales/fr/informationLowSelfEsteem.json";
import FRinformationRejection from "locales/fr/informationRejection.json";
import FRinformationRumination from "locales/fr/informationRumination.json";
import FRinformationSolitude from "locales/fr/informationSolitude.json";
import FRpasswordLostPage from "locales/fr/passwordLostPage.json";
import FRpasswordResetFormPage from "locales/fr/passwordResetFormPage.json";
import FRpasswordResetPage from "locales/fr/passwordResetPage.json";
import FRplanResponse from "locales/fr/planResponse.json";
import FRstatisticPage from "locales/fr/statisticPage.json";

const resources = {
  en: {
    accountPage: ENaccountPage,
    dialogAffectedByMyEmotion: ENdialogAffectedByMyEmotion,
    dialogDeleteEmotion: ENdialogDeleteEmotion,
    dialogDeleteEntries: ENdialogDeleteEntries,
    dialogDeletePlanResponse: ENdialogDeletePlanResponse,
    dialogEditEntries: ENdialogEditEntries,
    dialogEditPlanResponse: ENdialogEditPlanResponse,
    dialogEmotionAllowedMeToAdd: ENdialogEmotionAllowedMeToAdd,
    dialogFactAdd: ENdialogFactAdd,
    dialogNewPlanResponse: ENdialogNewPlanResponse,
    dialogPerceptionOriginAdd: ENdialogPerceptionOriginAdd,
    dialogPerceptionThinkingAdd: ENdialogPerceptionThinkingAdd,
    dialogPerceptionWitnessAdd: ENdialogPerceptionWitnessAdd,
    dialogPersonPresentAdd: ENdialogPersonPresentAdd,
    dialogPlaceAdd: ENdialogPlaceAdd,
    dialogTitleAdd: ENdialogTitleAdd,
    emotionAdd: ENemotionAdd,
    emotionList: ENemotionList,
    emotionType: ENemotionType,
    errorBoundary: ENerrorBoundary,
    homePage: ENhomePage,
    informationPage: ENinformationPage,
    informationFailure: ENinformationFailure,
    informationGuilt: ENinformationGuilt,
    informationLossAndTrauma: ENinformationLossAndTrauma,
    informationLowSelfEsteem: ENinformationLowSelfEsteem,
    informationRejection: ENinformationRejection,
    informationRumination: ENinformationRumination,
    informationSolitude: ENinformationSolitude,
    passwordLostPage: ENpasswordLostPage,
    passwordResetFormPage: ENpasswordResetFormPage,
    passwordResetPage: ENpasswordResetPage,
    planResponse: ENplanResponse,
    statisticPage: ENstatisticPage
  },
  fr: {
    accountPage: FRaccountPage,
    dialogAffectedByMyEmotion: FRdialogAffectedByMyEmotion,
    dialogDeleteEmotion: FRdialogDeleteEmotion,
    dialogDeleteEntries: FRdialogDeleteEntries,
    dialogDeletePlanResponse: FRdialogDeletePlanResponse,
    dialogEditEntries: FRdialogEditEntries,
    dialogEditPlanResponse: FRdialogEditPlanResponse,
    dialogEmotionAllowedMeToAdd: FRdialogEmotionAllowedMeToAdd,
    dialogFactAdd: FRdialogFactAdd,
    dialogNewPlanResponse: FRdialogNewPlanResponse,
    dialogPerceptionOriginAdd: FRdialogPerceptionOriginAdd,
    dialogPerceptionThinkingAdd: FRdialogPerceptionThinkingAdd,
    dialogPerceptionWitnessAdd: FRdialogPerceptionWitnessAdd,
    dialogPersonPresentAdd: FRdialogPersonPresentAdd,
    dialogPlaceAdd: FRdialogPlaceAdd,
    dialogTitleAdd: FRdialogTitleAdd,
    emotionAdd: FRemotionAdd,
    emotionList: FRemotionList,
    emotionType: FRemotionType,
    errorBoundary: FRerrorBoundary,
    homePage: FRhomePage,
    informationPage: FRinformationPage,
    informationFailure: FRinformationFailure,
    informationGuilt: FRinformationGuilt,
    informationLossAndTrauma: FRinformationLossAndTrauma,
    informationLowSelfEsteem: FRinformationLowSelfEsteem,
    informationRejection: FRinformationRejection,
    informationRumination: FRinformationRumination,
    informationSolitude: FRinformationSolitude,
    passwordLostPage: FRpasswordLostPage,
    passwordResetFormPage: FRpasswordResetFormPage,
    passwordResetPage: FRpasswordResetPage,
    planResponse: FRplanResponse,
    statisticPage: FRstatisticPage
  }
};

i18n
  .use(detector)
  //.use(backend) // Removed to optimize
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    react: {
      wait: true
    }
  });

export default i18n;
