import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import ButtonBase from '@material-ui/core/ButtonBase'
import { LEGAL_NOTICE_FR_PATH } from 'constants/paths'
import { Link } from 'react-router-dom'
import FrenchFlag from './../../../../../static/flag_french.png'

const LegalNoticePage = ({ classes }) => (
  <div className={classes.root}>
    <div>
      <ButtonBase
        component={Link}
        to={LEGAL_NOTICE_FR_PATH}
        className={classes.image}>
        <span
          className={[classes.imageSrc]}
          style={{
            backgroundImage: `url(${FrenchFlag})`
          }}
        />
        <span className={classes.imageBackdrop} />
      </ButtonBase>
    </div>
    <Paper className={classes.paper}>
      <h2 className={classes.title}>Legal Notice</h2>
      <p>
        Legal Notice:{' '}
        <a href="https://www.lfeapp.com">https://www.lfeapp.com</a>
      </p>
      <br />
      <p>Headquarter :</p>
      <p>Learning and Development Business Partners S.A.S. (L&D BP)</p>
      <p>67 rue Saint Jacques</p>
      <p>75005 Paris, France</p>
      <br />
      <p>Email : contact@lfeapp.com </p>
      <p>Phone : +33 (0)1 49 11 46 65</p>
      <br />
      <p>Trade and Business Registrar of Paris : B 832 724 272</p>
      <p>VAT number : FR 05 832724272</p>
      <br />
      <p>Website made by : Learning and Development Business Partners S.A.S.</p>
      <p>Publication Manager : Pierre de Gentile, contact@lfeapp.com</p>
      <br />
      <p>Hosts :</p>
      <p>1&1 IONOS SARL France</p>
      <p>Firebase, servers in France regulated by French law</p>
    </Paper>
  </div>
)

LegalNoticePage.propTypes = {
  classes: PropTypes.object // from enhancer (withStyles)
}

export default LegalNoticePage
