export default theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  children: {
    order: '1',
    width: '100%',
    flex: '13',
    overflowY: 'scroll'
  }
})
