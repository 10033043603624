import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'

export default theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  messageContainer: {
    flex: 9
  },
  actionContainer: {
    paddingLeft: 0
  },
  icon: {
    fontSize: 25
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
})
