import { Loadable } from 'utils/components'
import { LOGIN_PATH_FRENCH_PATH as path } from 'constants/paths'

export default {
  path,
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: 'LoginFrench' */ './components/LoginPage')
  })
}
