export default theme => ({
  flex: {
    flexGrow: 1
  },
  root: {
    width: '100%',
    flex: '1',
    order: '2',
    borderTop: '1px solid #E1E1F9'
  },
  buttonRoot: {
    color: '#9c9c9c',
    [theme.breakpoints.down('xs')]: {
      minWidth: '0px'
    }
  }
})
