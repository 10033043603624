import { Loadable } from 'utils/components'
import { INVITATION_CODE_PATH as path } from 'constants/paths'

export default {
  path,
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: 'InvitationCode' */ './components/InvitationCodePage')
  })
}
