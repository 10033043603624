import { Loadable } from "utils/components";
import { ADMIN_PATH as path } from "constants/paths";

export default {
  path,
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: 'ClientList' */ "./components/AdminPage"),
  }),
};
