import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import ButtonBase from '@material-ui/core/ButtonBase'
import { PRIVACY_POLICY_EN_PATH } from 'constants/paths'
import { Link } from 'react-router-dom'
import EnglishFlag from './../../../../../static/flag_united_kingdom.png'

const PrivacyPolicyPage = ({ classes }) => (
  <div className={classes.root}>
    <div>
      <ButtonBase
        component={Link}
        to={PRIVACY_POLICY_EN_PATH}
        className={classes.image}>
        <span
          className={[classes.imageSrc]}
          style={{
            backgroundImage: `url(${EnglishFlag})`
          }}
        />
        <span className={classes.imageBackdrop} />
      </ButtonBase>
    </div>
    <Paper className={classes.paper}>
      <h2 className={classes.title}>Politique de confidentialité</h2>
      <p>
        La société Learning and Development Business Partners, société par
        action simplifiée de droit français, au capital social de EUR 20 000,
        dont le siège social est situé au 67 rue Saint Jacques 75005 Paris,
        immatriculée auprès du Registre de Commerce et des Sociétés de Paris
        sous le numéro B 832 724 272, et qui a pour numéro de TVA FR 05
        832724272 (ci-après “ L&D BP” ou “ nous ”) s'engage à protéger et à
        respecter votre vie privée dans le cadre de votre utilisation de notre
        site Web <a href="https://www.lfeapp.com">https://www.lfeapp.com</a> (le
        "Site"), de nos applications mobiles (les "Apps") et de nos autres
        produits et services (le Site, les Apps et les autres produits et
        services sont collectivement appelés ici les "Services").
      </p>
      <p>
        Cette politique de confidentialité définit comment nous gérons les
        renseignements et informations de nature à vous vous identifier,
        directement ou indirectement (les "Données Personnelles") lorsque vous
        utilisez nos Services. Merci de lire attentivement ce qui suit pour
        comprendre nos pratiques concernant vos Données Personnelles.
      </p>
      <h3>1. Les Données Personnelles que nous traitons</h3>
      <h4>1. Celles que vous fournissez directement.</h4>
      <p>
        En utilisant nos Services, notamment lorsque vous créez un compte, vous
        connectez à celui-ci, visualisez nos contenus, vous êtes amenés à
        renseigner et ainsi à nous fournir certaines informations ayant la
        nature de Données Personnelles.
      </p>
      <p>
        Ces informations incluent, dans la mesure nécessaire et sans que cette
        énumération ne soit limitative :
      </p>
      <ul>
        <li>
          Les Données Personnelles nécessaires à l’inscription à nos Services, à
          savoir votre adresse email et mot de passe, genre, tranche d'âge et
          fuseau horaire
        </li>
        <li>
          Les Données Personnelles que vous nous fournissez en vue d’entrer en
          relation avec nous, notamment via le formulaire de contact accessible
          en ligne, telles que votre adresse email, le contenu de votre demande
          et les éventuelles pièces jointes fournies, votre numéro de téléphone,
          etc. Nous pouvons être amenés à vous demander des informations
          complémentaires lorsque vous nous signalez un problème concernant nos
          Services, en vue de la résolution de ce problème
        </li>
        <li>
          L’adresse IP, le modèle de l’appareil, le navigateur et la langue de
          l’appareil dont vous faites usage pour vous connecter aux Services ;
          une copie de la correspondance (y compris par email) que vous êtes
          susceptible de nous adresser
        </li>
        <li>
          Les détails des transactions que vous effectuez via nos services et
          des commandes que vous envoyez via nos services.
        </li>
        <li>
          Le cas échéant, vos réponses aux sondages auxquels nous vous invitons
          à participer, ainsi que le contenu de vos publications sur notre Site.
          Ces informations sont susceptibles d’être utilisées dans le cadre de
          recherches et d’analyses du comportement utilisateur, an d’améliorer
          nos Services
        </li>
        <li>
          Le cas échéant, les Données Personnelles que vous nous fournissez en
          vue de participer à un concours ou à une de nos promotions
          sponsorisées, afin de valider votre inscription
        </li>
        <li>
          Le détail de vos visites sur le Site et les Apps et liés à
          l'utilisation des Services, y compris, mais sans s'y limiter, les
          données de trafic, les données de localisation, les ressources
          auxquelles vous accédez
        </li>
      </ul>
      <h4>2. Celles que nous collectons automatiquement</h4>
      <h5>a. Votre utilisation de nos Services</h5>
      <p>
        Les fichiers journaux nous permettent d'enregistrer votre utilisation
        des Services. Notre équipe technique rassemble les informations du
        journal de fichier de tous nos utilisateurs, que nous utilisons pour
        apporter des améliorations à nos Services et nous informer sur la façon
        dont les utilisateurs utilisent les Services (par exemple nombre de
        petits bonheurs, nombre d'émotions saisies, etc.). Ces informations sont
        publiées sur votre compte utilisateur.
      </p>
      <h5>b. Cookies</h5>
      <p>
        Lors de chacune de vos visites, nous sommes susceptibles de recueillir,
        conformément à la législation applicable, des informations relatives aux
        appareils sur lesquels vous utilisez nos Services ou aux réseaux depuis
        lesquels vous accédez à nos Services, tels que notamment vos adresses
        IP, données de connexion, types et versions de navigateurs internet
        utilisés, systèmes et plateformes d’exploitation, données concernant
        votre parcours de navigation sur notre Site et nos Apps, les erreurs de
        téléchargement, le contenu auquel vous accédez ou consultez, etc.
      </p>
      <p>Pour ce faire, nous avons notamment recours aux cookies.</p>
      <p>
        <u>Qu'est-ce qu'un cookie ?</u>
      </p>
      <p>
        Un cookie est un petit fichier texte enregistré et/ou lu par votre
        navigateur, stocké dans le répertoire cookies du disque dur de votre
        appareil (PC, ordinateur portable ou smartphone), et déposé par les
        sites internet que vous visitez. Il contient un identifiant unique et
        anonyme.
      </p>
      <p>
        <u>Quels cookies utilisons-nous ?</u>
      </p>
      <p>
        Lorsque nous fournissons des Services, nous voulons les rendre faciles à
        utiliser, simples, pratiques, utiles et fiables. Pour ce faire, nous
        utilisons plusieurs catégories de cookies :
      </p>
      <ul>
        <li>
          Des cookies absolument nécessaires, afin de vous permettre de vous
          déplacer sur le Site et les Apps et d’utiliser leurs fonctionnalités
          de base. Ces cookies sont indispensables pour l’utilisation de nos
          Services
        </li>
        <li>Des cookies de sécurité</li>
        <li>
          Avec votre consentement, des cookies d’authentification, qui vous
          dispensent d’avoir à fournir votre identifiant et mot de passe lors de
          chaque nouvelle connexion à nos Services
        </li>
        <li>
          Avec votre consentement, des cookies de personnalisation de
          l’interface utilisateur : ces cookies permettent de mémoriser les
          préférences d’un utilisateur (prénom, préférence linguistique par
          exemple)
        </li>
        <li>
          Avec votre consentement, des cookies d’analyse et de performance qui
          nous permettent de reconnaître et de compter le nombre de visiteurs
          sur notre Site et nos Apps et de collecter des informations sur la
          manière dont ceux-ci sont utilisés. Cela nous permet d’améliorer la
          façon dont notre Site et nos Apps fonctionnent ainsi que plus
          généralement le contenu de nos Services. Ces cookies nous permettent
          également de mesurer l’efficacité de nos efforts marketing
        </li>
      </ul>
      <p>
        Les cookies seront effacés par nous passée une période de 13 mois à
        compter de leur date de dépôt.
      </p>
      <p>
        <u>Placement de cookies par des tiers</u>
      </p>
      <p>
        Les tiers auxquels nous faisons appel afin de connaître votre
        utilisation de nos Services ou à des fins marketing peuvent utiliser des
        cookies, que nous ne maîtrisons pas.
      </p>
      <p>
        Ce Site utilise notamment Google Analytics. Par exemple Google Analytics
        installe des cookies pour nous aider à estimer avec précision le nombre
        de visiteurs sur le Site et les volumes d'utilisation des Services, ceci
        pour assurer que les Services sont disponibles quand vous le souhaitez
        et que votre expérience soit assez rapide. Pour plus d'informations sur
        la façon dont Google Analytics traite cette information, visitez
        <a href="www.google.com/analytics">www.google.com/analytics</a>.
      </p>
      <p>
        Nous pouvons également utiliser les services de Google Adwords et
        Facebook Ads dans le cadre de nos efforts marketing. Ces services sont
        susceptibles d’installer des cookies sur votre appareil.
      </p>
      <p>
        De même, lorsque vous visitez une page avec du contenu hébergé par un
        tiers (par exemple, des vidéos YouTube) ou utilisez certains des liens
        vers des sites de réseaux sociaux (par exemple, le bouton "partager"),
        ces sites peuvent placer des cookies sur votre ordinateur.
      </p>
      <p>
        Nous ne contrôlons ni la manière dont ces tiers utilisent ces cookies,
        ni la durée pendant laquelle ils les conservent. Nous vous invitons à
        vérifier les politiques de confidentialité de ces sites tiers pour plus
        d'informations sur ce sujet.
      </p>
      <p>
        <u>Comment gérer les cookies ?</u>
      </p>
      <p>
        Lors de votre première visite sur le Site ou les Apps, nous vous donnons
        accès à la présente politique et vous demandons de faire un choix quant
        à l’acceptation ou au refus de l’utilisation de cookies tiers en vue de
        mesurer l’audience de notre Site ainsi que la performance de nos efforts
        marketing en ligne.
      </p>
      <p>
        Vous pourrez toujours modifier votre choix en nous contactant à
        l’adresse email{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>.
      </p>
      <p>
        Les paramètres de votre navigateur vous permettent également de modifier
        les paramètres d’acceptation des cookies.
      </p>
      <p>
        Bien que votre navigateur fonctionne de façon à autoriser la création de
        cookies, vous pouvez le configurer de façon à ce qu'une fenêtre vous
        demande systématiquement confirmation avant qu'un site ne mette un
        cookie sur votre disque dur, de sorte que vous puissiez décider
        d'autoriser ou d'interdire le cookie.
      </p>
      <p>
        Alternativement, vous pouvez configurer votre ordinateur pour n'accepter
        aucun cookie. Nos services ne prennent pas en charge un navigateur qui
        est signalé comme "à ne pas tracer".
      </p>
      <p>
        Si vous choisissez de ne pas accepter le cookie, cela n'affectera pas
        votre accès à la majeure partie du contenu et des éléments disponibles
        par l'intermédiaire de nos Services.
      </p>
      <h3>2. Pourquoi utilisons-nous vos Données Personnelles ?</h3>
      <p>
        Nous procédons aux traitements de vos Données Personnelles pour les
        finalités suivantes, lesquelles sont justifiées par l’exécution de nos
        obligations contractuelles et la satisfaction de vos demandes, ce qui
        constitue la base légale des traitements concernés :
      </p>
      <ul>
        <li>
          Vous permettre d’accéder à nos Services et vous fournir les
          informations et Services demandés
        </li>
        <li>Vous fournir une assistance client</li>
        <li>Percevoir vos paiements</li>
        <li>Vous informer des modifications apportées à nos Services</li>
        <li>
          Vous fournir un historique des émotions enregistrées ainsi que des
          informations d’ordre statistique sur vos usages de nos Services
        </li>
        <li>
          Vous envoyer, par email, des messages relatifs à l’utilisation de nos
          Services ou de coaching /de conseil afin de vous accompagner dans
          votre pratique et vous motiver
        </li>
        <li>
          Analyser les messages échangés par vous avec d’autres membres de notre
          communauté via le Site ou les Apps afin de s’assurer que nos
          conditions générales d’utilisation sont bien respectées.
        </li>
      </ul>
      <p>
        Le traitement de vos Données Personnelles est également dans d’autres
        cas justifié par notre intérêt légitime qui constitue alors le fondement
        légal du traitement dans les cas suivants :
      </p>
      <ul>
        <li>
          Améliorer et optimiser notre Site et nos Apps, notamment pour nous
          assurer de ce que l’affichage de nos contenus est adapté à votre
          ordinateur ou autre dispositif
        </li>
        <li>
          Gérer notre Site et nos Apps et effectuer des opérations techniques
          internes dans le cadre de résolution de problèmes, analyse de données,
          de tests, recherches, analyses, études et sondages pour assurer la
          sécurité de notre Site et de nos Apps et en améliorer les
          caractéristiques)
        </li>
        <li>
          Effectuer des statistiques de votre utilisation de nos Services afin
          de vous fournir des contenus adaptés à vos besoins et améliorer nos
          Services
        </li>
        <li>
          Vous envoyer par email, conformément aux dispositions légales
          applicables, des messages concernant des services similaires à ceux
          que vous avez utilisés ou vous transmettre des offres spéciales
          occasionnelles à leur sujet an d’assurer la promotion de nos Services
        </li>
        <li>
          Gérer les avis, incidents, réclamations et contentieux pour nous
          permettre de constater, exercer ou défendre nos droits en justice
        </li>
        <li>
          Mesurer la performance de nos efforts marketing pour le développement
          et pérennisation de nos activités
        </li>
      </ul>
      <p>
        Vos Données Personnelles sont également traitées pour nous permettre de
        donner suite à vos demandes de droit d’accès, de rectification et
        d’opposition, ou portant sur vos autres droits à l’égard des Données
        Personnelles. Ce traitement est alors fondé sur le respect de nos
        obligations légales.
      </p>
      <p>
        Enfin, les traitements liés à certains cookies (voir point 2 ci-dessus)
        ou relatifs à la réception par email d’informations de la part de tiers
        sont fondés sur votre consentement. Vous pouvez alors retirer celui-ci à
        tout moment alors qu’il s’agit de la seule base légale applicable.
      </p>
      <h3>3. À qui sont transmises vos Données Personnelles ?</h3>
      <p>
        Les Données Personnelles sont traitées en interne par les personnes
        dûment habilitées, dans la limite de leurs attributions respectives.
      </p>
      <p>
        Elles sont également susceptibles d’être communiquées aux destinataires
        externes suivants :
      </p>
      <ul>
        <li>Organismes financiers teneurs des comptes mouvementés</li>
        <li>
          Autorités administratives et judiciaires compétentes, telles que les
          autorités fiscales, afin de permettre à L&D BP de remplir ses
          obligations légales en la matière, d’appliquer ou faire appliquer ses
          accords avec vous, ou pour protéger les droits, la propriété ou la
          sécurité de L&D BP, de nos clients et de tous tiers
        </li>
        <li>
          Auxiliaires de justice et officiers ministériels dans le cadre de leur
          mission de recouvrement de créances
        </li>
        <li>
          Sous-traitants (notamment prestataires informatiques) et prestataires
          de services externes (tels que auditeurs, conseils juridiques,
          fiduciaires, fournisseurs de solutions analytiques ou de services de
          paiement, sociétés et organisations spécialisées dans la protection
          contre la fraude et la réduction du risque d’impayé, organisation
          chargée de l'hébergement de nos services, etc.), dans la stricte
          mesure nécessaire et sous réserve de l’existence de garanties
          contractuelles propres à assurer la sécurité et la confidentialité des
          données
        </li>
        <li>
          Lorsque vous en faites la demande expresse, nous pouvons être amenés à
          divulguer vos Données Personnelles à ce partenaire afin qu'il puisse
          vous fournir le service ou l’information sollicités. Il est de la
          responsabilité du tiers que vous autorisez à recevoir vos données de
          protéger ces informations conformément aux lois applicables, et ne les
          utiliser que pour fournir les services ou les renseignements que vous
          avez demandés
        </li>
        <li>
          Dans le cas où nous envisagerions de vendre ou d’acheter une activité
          ou des actifs, nous pourrons être amené, dans la stricte mesure
          nécessaire, à divulguer vos Données Personnelles au vendeur ou à
          l’acheteur potentiel d'une telle entreprise ou des actifs
        </li>
        <li>
          Si L&D BP ou la quasi-totalité de nos actifs sont acquis par un tiers,
          les Données Personnelles que nous détenons sur vous seront transmises
          audit repreneur
        </li>
      </ul>
      <h3>4. Comment protégeons-nous vos Données Personnelles ?</h3>
      <p>
        Les Données Personnelles recueillies par L&D BP sont traitées par elle,
        en qualité de responsable du traitement, dans le respect de la
        législation sur la protection des données applicable (à savoir notamment
        le Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27
        avril 2016, - RGPD -, ainsi que toute autre règlementation ultérieure).
      </p>
      <p>
        L&D BP s’engage à mettre en œuvre les mesures de sécurité techniques et
        organisationnelles de nature à assurer la protection des Données
        Personnelles contre les risques liés à l’usage des systèmes
        d’information, et notamment l’accès non autorisé à ces informations.
        Sauf en cas de divulgation de vos Données Personnelles telle que décrite
        au point 4 ci-dessus, nous ne fournissons pas vos Données Personnelles à
        une tierce partie sans votre consentement express.
      </p>
      <p>
        Toutes les informations que vous nous fournissez à travers nos Services
        sont stockées sur des serveurs sécurisés situés en France. Toutes les
        transactions de paiement seront cryptées en utilisant la technologie
        SSL. Lorsque vous avez choisi un mot de passe qui vous permet d'accéder
        à certaines parties des Services, vous êtes responsable du maintien de
        ce mot de passe condentiel. Nous vous demandons de ne pas partager un
        mot de passe avec quiconque. Malheureusement, la transmission
        d'informations via Internet n'est pas entièrement sécurisée. Bien que
        nous fassions de notre mieux pour protéger vos renseignements
        personnels, nous ne pouvons pas garantir la sécurité de vos informations
        transmises aux Services ; toute transmission est à vos risques et
        périls.
      </p>
      <h3>5. Combien de temps conservons-nous vos Données Personnelles</h3>
      <p>
        Vos Données Personnelles sont conservées pendant une durée de 10 ans à
        compter de la fermeture de votre compte ou de votre dernière utilisation
        de nos Services si vous n’avez pas fermé votre compte, ce qui correspond
        à la durée de prescription applicable. Ce délai peut être prorogé
        au-delà pendant la durée des procédures judiciaires ou administratives
        en cours le cas échéant.
      </p>
      <p>
        Toutefois, dès fermeture de votre compte ou passé un délai de deux (2)
        ans à compter de votre dernière utilisation de nos Services si vous
        n’avez pas fermé votre compte, vos Données Personnelles sont simplement
        stockées à des fins d’archivage de manière sécurisée pour répondre à nos
        obligations légales ou, le cas échéant, nous permettre de nous défendre
        en justice. Elles ne sont pas communiquées à des tiers ou utilisées
        autrement.
      </p>
      <h3>6. Quels sont vos droits sur vos données personnelles ?</h3>
      <p>Vous disposez du droit d’obtenir, sans frais :</p>
      <ul>
        <li>
          L’accès aux Données Personnelles vous concernant et leur rectification
        </li>
        <li>
          La confirmation que des Données Personnelles vous concernant sont ou
          ne sont pas traitées
        </li>
        <li>
          Des informations portant au moins sur les finalités du traitement, sur
          les catégories de Données Personnelles sur lesquelles le traitement
          porte et les destinataires ou les catégories de destinataires auxquels
          les Données Personnelles sont communiquées
        </li>
        <li>
          La communication, sous une forme intelligible, des Données
          Personnelles faisant l’objet des traitements, ainsi que de toute
          information disponible sur l’origine des données
        </li>
      </ul>
      <p>
        Vous disposez également des droits suivants, dans les limites prévues
        par les règles applicables et sous réserve de justifier de votre
        identité :
      </p>
      <ul>
        <li>
          Demander l’effacement de tout ou partie des données ou une limitation
          du traitement
        </li>
        <li>Vous opposer au traitement de vos données</li>
        <li>
          Faire usage de votre droit à la portabilité des Données Personnelles
        </li>
      </ul>
      <p>
        Ces droits peuvent être exercés par email, adressé à l’adresse suivante
        : <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>.N’hésitez
        pas à nous contacter à cette adresse également pour toute précision
        complémentaire sur le contenu de vos droits ou la manière de les
        exercer.
      </p>
      <p>
        Vous pouvez accéder à vos Données Personnelles et les modier à tout
        moment via votre compte en ligne.
      </p>
      <p>
        Vous disposez également de la possibilité de porter plainte, en cas de
        manquement(s) aux règles applicables en matière de protection des
        données personnelles, devant une autorité de supervision telle que la
        CNIL.
      </p>
      <p>
        Vous avez le droit de nous demander de ne pas utiliser vos Données
        Personnelles à des fins de marketing pour vous adresser des informations
        sur nos propres Services. Vous pouvez exercer votre droit d'empêcher un
        tel traitement en suivant les instructions de l'opt-out dans le matériel
        de marketing que vous recevez ou en communiquant avec nous à tout moment
        par email à <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>{' '}
        sur votre interface utilisateur.
      </p>
      <p>
        Les Services peuvent, de temps à autre, contenir des liens vers des
        sites Web de nos réseaux de partenaires, annonceurs et affiliés. Si vous
        suivez un lien vers un de ces sites, merci de noter que ces sites ont
        leurs propres politiques de confidentialité et que nous déclinons toute
        responsabilité quant à l’utilisation faite par ces sites des
        informations collectées lorsque vous cliquez sur ces liens. Nous vous
        invitons à prendre connaissance des politiques de confidentialité de ces
        sites avant de leur transmettre vos Données Personnelles.
      </p>
      <h3>7. Vie privée des enfants</h3>
      <p>
        Les Services sont conçus pour les personnes âgées de 18 ans et plus. Les
        enfants entre les âges de 13 et 18 ans (ou l'âge de la majorité dans
        leur pays de résidence) non émancipés (les "mineurs") peuvent utiliser
        les Services, mais l'utilisation par les mineurs doit être effectué
        uniquement sous la supervision, le contrôle et avec le consentement de
        leurs représentants légaux. Nous comptons sur les parents et les tuteurs
        pour s'assurer que les mineurs utilisent les services en comprenant
        leurs droits et responsabilités comme indiqués dans les conditions
        d'utilisation et de vente applicables aux Services (les "Conditions").
        Si vous êtes un représentant légal d'un mineur et que vous donnez votre
        consentement à l'utilisation des Services par l'enfant dont vous avez la
        responsabilité légale, vous acceptez d'être lié par cette politique de
        confidentialité et les Conditions. Les enfants de moins de 13 ans ne
        sont pas autorisés à utiliser les Services. Si vous êtes sous l'âge de
        13 ans, merci de ne pas tenter d'accéder ou d'utiliser les Services. Si
        vous croyez que nous avons pu recueillir des renseignements personnels
        d'un enfant de moins de 13 ans, s'il vous plait nous en aviser à{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a> que nous
        puissions supprimer ces informations.
      </p>
      <h3>8. Changements à notre politique de confidentialité</h3>
      <p>
        L&D BP se réserve le droit de modifier ou mettre à jour cette politique
        de confidentialité, ou tout autre de nos politiques ou pratiques, à tout
        moment, et informer les utilisateurs des Services en publiant la
        politique de confidentialité modifiée ou mise à jour sur la page
        d’accueil du Site ou de l’App en question. Toute modication entrera en
        vigueur immédiatement après cette publication. Votre utilisation
        continue des Services constitue votre accord de se conformer à la
        politique de confidentialité telle que modifiée. Dans certaines
        circonstances (par exemple, si nous souhaitons utiliser vos Données
        Personnelles pour des finalités différentes de celles indiquées dans
        notre politique de confidentialité au moment de la collecte), lorsque
        cela est nécessaire, nous vous en informerons personnellement et /ou
        solliciterons votre accord.
      </p>
      <h3>9. Contactez-nous</h3>
      <p>
        Vos questions, commentaires et demandes concernant cette politique de
        confidentialité sont les bienvenus et doivent être adressés à{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>.
      </p>
      <p>
        Cette politique de confidentialité est effective et a été mise à jour le
        01/09/2019.
      </p>
    </Paper>
  </div>
)

PrivacyPolicyPage.propTypes = {
  classes: PropTypes.object // from enhancer (withStyles)
}

export default PrivacyPolicyPage
