import styles from './HomePage.styles'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { setDisplayName } from 'recompose'
import { spinnerWhileLoading } from 'utils/components'
import { UserIsAuthenticated } from 'utils/router'
import { withFirebase } from 'react-redux-firebase'
import { connect } from 'react-redux'

export default compose(
  setDisplayName('HomePage'),

  UserIsAuthenticated,

  withFirebase,
  connect(({ firebase: { profile } }) => ({
    profile
  })),
  spinnerWhileLoading(['profile']),

  withStyles(styles)
)
