import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import ButtonBase from '@material-ui/core/ButtonBase'
import { PRIVACY_POLICY_FR_PATH } from 'constants/paths'
import { Link } from 'react-router-dom'
import FrenchFlag from './../../../../../static/flag_french.png'

const PrivacyPolicyPage = ({ classes }) => (
  <div className={classes.root}>
    <div>
      <ButtonBase
        component={Link}
        to={PRIVACY_POLICY_FR_PATH}
        className={classes.image}>
        <span
          className={[classes.imageSrc]}
          style={{
            backgroundImage: `url(${FrenchFlag})`
          }}
        />
        <span className={classes.imageBackdrop} />
      </ButtonBase>
    </div>
    <Paper className={classes.paper}>
      <h2 className={classes.title}>Privacy policy</h2>
      <p>
        Learning and Development Business Partners S.A.S., a limited company
        under French law, with a shared capital of EUR 20,000, headquartered at
        67 rue Saint Jacques 75005 Paris, registered with the Trade and Business
        Registrar of Paris under number B 832 724 272, and having the VAT number
        FR 05 832724272 (hereinafter "L&D BP" or "us"). is committed to protect
        and respect your privacy while using our website{' '}
        <a href="https://www.lfeapp.com">https://www.lfeapp.com</a>
        (the Site), our mobile applications ("Apps") and other products and
        services (the Site, the Apps and other products and services are
        collectively called hereinafter the "Services").
      </p>
      <p>
        This privacy policy defines how we manage information and data that
        identifies you, directly or indirectly (the "Personal Data") when you
        use our Services. Please read the following carefully to understand our
        practices regarding your Personal Data.
      </p>
      <h3>1. Personal data that we process</h3>
      <h4>1. Those that you provide directly.</h4>
      <p>
        By using our Services, especially when you create an account, you
        connect to it, view our content, you are prompted to provide information
        and thus provide us with some Personal Data type information.{' '}
      </p>
      <p>
        This information includes, to the extent necessary and without this list
        being exhaustive:
      </p>
      <ul>
        <li>
          Personal Data necessary to register for our Services, namely your
          email address, password, gender, age range and time zone
        </li>
        <li>
          The Personal Data that you provide to us in order to contact us, in
          particular via the online contact form, such as your email address,
          the content of your request and any attachments provided, your
          telephone number, etc. We may ask you for additional information when
          you report a problem with our Services to resolve the issue
        </li>
        <li>
          The IP address, device model, browser, and language of the device you
          use to connect to the Services; A copy of the correspondence
          (including by email) that you are likely to send us{' '}
        </li>
        <li>
          The details of the transactions you conduct through our services and
          orders you send via our services{' '}
        </li>
        <li>
          If applicable, your responses to surveys that we invite you to
          participate, as well as the content of your publications on our Site.
          This information may be used for research and analysis of user
          behavior to improve our Services{' '}
        </li>
        <li>
          The details of your visits to the Site and related to the use of the
          Services, including but not limited to traffic data, location data,
          resources accessed, weblogs and other communication data if necessary,
          for our own billing purposes
        </li>
        <li>Time spent on the Services </li>
      </ul>
      <h4>2. Those we collect automatically</h4>
      <h5>a. Your use of our Services</h5>
      <p>
        Log files allow us to record your use of the Services. Our technical
        team collects the file log information of all our users, which we use to
        make improvements to our Services and inform us about how users use the
        Services (eg number of little moments of happiness, number of emotions,
        etc.). This information is published on your user account.
      </p>
      <h5>b. Cookies</h5>
      <p>
        Subject to applicable law, during each of your visits we may collect
        information related to the devices on which you use our Services or the
        networks from which you access our Services, such as your IP addresses,
        connection data, types and versions of internet browsers used, operating
        systems and platforms, data about your navigation on our Site and our
        Apps, download errors, content you access or views, etc.{' '}
      </p>
      <p>We use cookies for this purpose.</p>
      <p>
        <u>What is a cookie?</u>
      </p>
      <p>
        A cookie is a small text file saved and / or read by your browser,
        stored in the cookie directory of your device's hard drive (PC, laptop
        or smartphone) installed by the websites you visit. It contains a unique
        and anonymous identifier.
      </p>
      <p>
        <u>Which cookies do we use?</u>
      </p>
      <p>
        When we provide Services, we want to make them easy to use, simple,
        convenient, useful and reliable. We use several cookie categories for
        this purpose:
      </p>
      <ul>
        <li>
          Absolutely necessary cookies, in order to allow you to move on the
          Site and the Apps and to use their basic functionalities. These
          cookies are essential for the use of our Services{' '}
        </li>
        <li>Security cookies</li>
        <li>
          With your consent, authentication cookies, which exempt you from
          having to provide your username and password each time you connect to
          our Services
        </li>
        <li>
          With your consent, cookies for personalization of the user interface:
          these cookies make it possible to memorize the preferences of a user
          (first name, linguistic preference for example)
        </li>
        <li>
          With your consent, analytics and performance cookies that allow us to
          recognize and count the number of visitors to our Site and Apps and to
          collect information on how they are used. This allows us to improve
          the way our Site and our Apps work as well as more generally the
          content of our Services. These cookies also allow us to measure the
          effectiveness of our marketing efforts
        </li>
      </ul>
      <p>
        Cookies will be deleted by us after 13 months from the date of
        placement.
      </p>
      <p>
        <u>Placing cookies by third parties</u>
      </p>
      <p>
        The third parties we use to know your use of our Services or for
        marketing purposes may use cookies, which we do not control.{' '}
      </p>
      <p>
        This Site uses, in particular Google Analytics. For example, Google
        Analytics installs cookies to help us accurately estimate the number of
        visitors to the Site and the usage volumes of the Services to ensure
        that Services are available whenever you want and that your experience
        is fast enough. For more information on how Google Analytics processes
        this information, visit{' '}
        <a href="www.google.com/analytics">www.google.com/analytics</a>.
      </p>
      <p>
        We may also use the services of Google Adwords and Facebook Ads as part
        of our marketing campaigns, these sites may place cookies on your
        computer.{' '}
      </p>
      <p>
        Similarly, when you visit a page with content hosted by a third party
        (for example, YouTube videos) or use some of the links to social
        networking sites (for example, the "share" button), these sites may
        place cookies on your computer.
      </p>
      <p>
        We do not control how these third parties use these cookies, nor how
        long they keep them. We invite you to review the privacy policies of
        these third-party sites for more information on this topic.
      </p>
      <p>
        <u>How do you manage cookies ?</u>
      </p>
      <p>
        During your first visit to the Site or the Apps, we give you access to
        this policy and ask you to make a choice as to whether to accept or
        refuse the use of third-party cookies to measure the audience. our Site
        and the performance of our online marketing efforts.
      </p>
      <p>
        You can always modify your choice by contacting us at the email address{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>.
      </p>
      <p>
        Your browser settings also allow you to change the cookie acceptance
        settings.
      </p>
      <p>
        Although your browser works to allow cookies, you can set it up so that
        a window always asks you for confirmation before a site stores a cookie
        in your hard drive, so you can decide to allow or reject the cookie.
        Alternatively, you can configure your computer to accept no cookies. Our
        services do not support browsers set to "block cookies".
      </p>
      <p>
        If you choose not to accept the cookie, it will not affect your access
        to most of the content and materials available through our services.{' '}
      </p>
      <h3>2. Why do we use your Personal Data ?</h3>
      <p>
        We proceed to the processing of your Personal Data for the following
        purposes, which are justified by the performance of our contractual
        obligations and the satisfaction of your requests, which constitutes the
        legal basis of the processing concerned :{' '}
      </p>
      <ul>
        <li>
          Allow you to access our Services and provide you with the requested
          information and Services{' '}
        </li>
        <li>Provide you with customer support</li>
        <li>Collect your payments</li>
        <li>Inform you of changes to our Services</li>
        <li>
          Provide you with statistical information on your use of our Services
        </li>
        <li>
          Send you messages by email related to the use of our Services or
          coaching/counseling to assist you in your practice and motivate you
        </li>
        <li>
          Analyze the messages you have exchanged with other members of our
          community via the Site or the Apps to ensure that our terms and
          conditions are respected
        </li>
      </ul>
      <p>
        The processing of your Personal Data is also in other cases justified by
        our legitimate interest which then constitutes the legal basis of the
        treatment in the following cases :
      </p>
      <ul>
        <li>
          improve and optimize our Site and our Apps, in particular to ensure
          that the display of our content is adapted to your computer or other
          device
        </li>
        <li>
          manage our Site and Apps and perform in-house technical
          troubleshooting, data analysis, testing, research, analysis, studies
          and surveys to ensure the security of our Site and Apps and improve
          its features)
        </li>
        <li>
          perform statistics on your use of our Services to provide you with
          content tailored to your needs and to improve our Services
        </li>
        <li>
          send you by email, in accordance with the applicable legal provisions,
          messages concerning services similar to those you have used or send
          you occasional special offers about them in order to promote our
          Services
        </li>
        <li>
          manage notices, incidents, claims and litigation to enable us to
          ascertain, exercise or defend our rights in court
        </li>
        <li>
          measure the performance of our marketing efforts for the development
          and sustainability of our activities
        </li>
      </ul>
      <p>
        Your Personal Data is also processed to enable us to respond to your
        requests for rights of access, rectification and opposition, or your
        other rights with respect to Personal Data. This treatment is then based
        on the respect of our legal obligations.
      </p>
      <p>
        Finally, the processing related to certain cookies (see point 2 above)
        or related to the receipt by email of information from third parties are
        based on your consent. You can then withdraw it at any time while it is
        the only legal basis applicable.
      </p>
      <h3>3. To whom are your Personal Data transmitted ?</h3>
      <p>
        Personal Data is processed internally by duly authorized people within
        the limits of their respective duties.
      </p>
      <p>
        They are also likely to be communicated to the following external
        recipients :
      </p>
      <ul>
        <li>financial institutions that are account holders</li>
        <li>
          competent administrative and judicial authorities, such as the tax
          authorities, to enable Learning and Development Business Partners to
          fulfill its legal obligations in the matter, to apply or enforce its
          agreements with you, or to protect the rights, property or safety of
          Learning and Development Business Partners, our customers and all
          third parties
        </li>
        <li>
          paralegals and departmental officers as part of their debt collection
          duties{' '}
        </li>
        <li>
          subcontractors (including IT service providers) and external service
          providers (such as auditors, legal advisers, trustees, analytical
          solution or payment service providers, companies and organizations
          specializing in fraud protection and risk reduction unpaid, etc.), to
          the extent necessary and subject to the existence of contractual
          safeguards to ensure the security and confidentiality of the data
        </li>
        <li>
          When you make an express request, we may be required to disclose your
          Personal Data. to this partner so that he can provide you with the
          requested service or information. It is the responsibility of the
          third party you authorize to receive your data to protect such
          information in accordance with applicable law, and to use it only to
          provide the services or information you have requested
        </li>
        <li>
          In the event that we consider selling or buying an activity or assets,
          we may be required, to the extent strictly necessary, to disclose your
          Personal Data to the seller or potential buyer of such business or
          assets
        </li>
        <li>
          If Learning and Development Business Partners SAS or substantially all
          of our assets are acquired by a third party, the Personal Data that we
          hold about you will be transferred to that buyer
        </li>
      </ul>
      <h3>4. How do we protect your Personal Data ?</h3>
      <p>
        The Personal Data collected by Learning and Development Business
        Partners are processed by it, as data controller, in compliance with the
        applicable data protection legislation (ie in particular Regulation (EU)
        2016/679 of the European Parliament and of the Council of 27 April 2016,
        - the GDPR -, as well as any subsequent regulations).
      </p>
      <p>
        Learning and Development Business Partners undertakes to implement the
        technical and organizational security measures to ensure the protection
        of Personal Data against the risks associated with the use of
        information systems, including unauthorized access to such information.
        Except in case of disclosure of your Personal Data as described in point
        4 above, we do not provide your Personal Data to a third party without
        your express consent.
      </p>
      <p>
        All information that you provide to us through our services is stored on
        our secure servers located in France. All payment transactions will be
        encrypted using SSL technology. When you have chosen a password that
        allows you to access certain parts of the services, you are responsible
        for maintaining this password confidential. We ask you not to share a
        password with anyone. Unfortunately, transmitting information via the
        Internet is not completely secure. Although we do our best to protect
        your personal information, we cannot guarantee the security of your
        information transmitted to the services; any transmission is at your own
        risk.
      </p>
      <h3>5. How long do we keep your personal data</h3>
      <p>
        Your Personal Data is retained for a period of 10 years from the closing
        of your account or your last use of our Services if you have not closed
        your account, which corresponds to the applicable limitation period.
        This period may be extended beyond the duration of the judicial or
        administrative proceedings in progress, as the case may be.
      </p>
      <p>
        However, upon closing of your account or after a period of two (2) years
        from your last use of our Services if you have not closed your account,
        your Personal Data is simply stored for archival purposes. secure to
        meet our legal obligations or, if necessary, allow us to defend
        ourselves in court. They are not communicated to third parties or used
        otherwise.
      </p>
      <h3>6. What are your rights in respect of your personal data ?</h3>
      <p>You have the right to obtain, free of charge :</p>
      <ul>
        <li>access to Personal Data about you and their rectification</li>
        <li>
          confirmation as to whether or not data related to him or her are being
          processed
        </li>
        <li>
          information at least as to the purposes of the processing operation,
          the categories of data concerned, and the recipients or categories of
          recipients to whom the data are disclosed
        </li>
        <li>
          communication, in an intelligible form, containing personal data
          undergoing processing, and any available information pertaining the
          data source
        </li>
      </ul>
      <p>
        You also have the following rights, within the limits provided by the
        applicable rules and subject to the proof of your identity :
      </p>
      <ul>
        <li>
          request the deletion of all or part of the data or a limitation of the
          treatment
        </li>
        <li>oppose the processing of your data</li>
        <li>make use of your right to the portability of Personal Data</li>
      </ul>
      <p>
        These rights can be exercised by email sent to the following address:{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>. Do not
        hesitate to contact us at this address also for any further
        clarification on your rights' content or how to exercise them.
      </p>
      <p>
        You may access your Personal Data and modify it at any time via your
        online account.
      </p>
      <p>
        You also have the option to lodge a complaint in the event of a breach
        of the rules applicable to the protection of personal data, before a
        supervisory authority such as the CNIL.
      </p>
      <p>
        You have the right to ask us not to use your personal information for
        marketing purposes to send you information about our own services. You
        may exercise your right to prevent such processing by following the
        opt-out instructions in the marketing material you receive or by
        contacting us at any time by email{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>.
      </p>
      <p>
        The Services may, from time to time, contain links to the websites of
        our partner, advertisers and affiliate networks. If you follow a link to
        one of these sites, please note that these sites have their own privacy
        policies and that we are not responsible for the use made by these sites
        of the information collected when you click on these links. Please read
        these sites' privacy policies before sending them your Personal Data.
      </p>
      <h3>7. Children's Privacy</h3>
      <p>
        The Services are designed for people aged 18 and over. Children between
        the ages of 13 and 18 (or the age of majority in their country of
        residence) who are not emancipated ("minors") may use the Services, but
        the use by minors must be made only under the supervision, control and
        with the consent of their legal representatives. We rely on parents and
        guardians to ensure that minors use the services by understanding their
        rights and responsibilities as set forth in the Terms of Use and Sale
        applicable to the Services (the "Terms"). If you are a legal
        representative of a minor and you consent to the use of the Services by
        the child you are legally responsible for, you agree to be bound by this
        Privacy Policy and the Terms. Children under the age of 13 are not
        allowed to use our services. If you are under the age of 13, please do
        not attempt to access or use the Services. If you believe we have been
        able to collect personal information from a child under the age of 13,
        please let us know at{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a> so we can
        remove this information.
      </p>
      <h3>8. Change to our privacy policy</h3>
      <p>
        Learning and Development Business Partners reserves the right to modify
        or update this Privacy Policy, or any of our policies or practices, at
        any time, and to notify users of the Services by posting the modified or
        updated Privacy Policy on this page. Any changes will take effect
        immediately after this publication. Your continued use of the Services
        constitutes your agreement to comply with the Privacy Policy as amended.
        In certain circumstances (for example, if we wish to use your Personal
        Data for purposes other than those stated in our privacy policy at the
        time of collection), when necessary, we will notify you personally and /
        or seek your consent.
      </p>
      <h3>9. Contact us</h3>
      <p>
        Your questions, comments and requests regarding this privacy policy are
        welcome and should be sent to{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>.
      </p>
      <p>This privacy policy is live and was updated on 01/09/2019.</p>
    </Paper>
  </div>
)

PrivacyPolicyPage.propTypes = {
  classes: PropTypes.object // from enhancer (withStyles)
}

export default PrivacyPolicyPage
