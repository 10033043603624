export const INIT_PATH = "/starter";
export const LIST_PATH = "/projects";
export const ACCOUNT_PATH = "/account";
export const HOME_PATH = "/";
export const LOGIN_PATH_ENGLISH_PATH = "/en/login";
export const LOGIN_PATH_FRENCH_PATH = "/fr/login";
export const SIGNUP_PATH_ENGLISH_PATH = "/en/signup";
export const SIGNUP_PATH_FRENCH_PATH = "/fr/signup";
export const PASSWORD_LOST_PATH = "/password-lost";
export const PASSWORD_RESET_PATH = "/password-reset";
export const EMOTION_NEW_PATH = "/emotions/add";
export const EMOTION_ADD_FULL_PATH = "/emotions/add-full";
export const EMOTION_ADD_LITTLE_HAPPINESS_PATH =
  "/emotions/add-little-happiness";
export const EMOTION_ADD_PLANNED_RESPONSE_PATH =
  "/emotions/add-planned-response";
export const EMOTION_SELECT_TYPE_PATH = "/emotions/type";
export const EMOTION_LIST_PATH = "/emotions";
export const EMOTION_EDIT_PATH = "/emotions/edit";
export const EMOTION_EDIT_FULL_PATH = "/emotions/edit-full";
export const EMOTION_EDIT_LITTLE_HAPPINESS_PATH =
  "/emotions/edit-little-happiness";
export const EMOTION_EDIT_PLANNED_RESPONSE_PATH =
  "/emotions/edit-planned-response";
export const EMOTION_DETAIL_PATH = `/emotions/completed`;
export const EMOTION_DETAIL_FULL_PATH = `/emotions/completed/:emotionId`;
export const STATISTIC_PATH = "/statistic";
export const INFORMATION_PATH = "/information";
export const INFORMATION_BTK_EMOTION_DECISION_PATH =
  "/information/basics-to-know/emotion-decision";
export const INFORMATION_BTK_IDENTIFY_EMOTION_PATH =
  "/information/basics-to-know/identify-emotion";
export const INFORMATION_BTK_FACILITATE_BEHAVIOR_PATH =
  "/information/basics-to-know/facilitate-behavior";
export const INFORMATION_BTK_BENEFIT_GRATITUDE_PATH =
  "/information/basics-to-know/benefit-gratitude";
export const INFORMATION_BTK_DICTIONARY_EMOTION_PATH =
  "/information/basics-to-know/dictionary-emotion";
export const INFORMATION_FAK_REJECTION_PATH =
  "/information/first-aid-kit/rejection";
export const INFORMATION_FAK_FAILURE_PATH =
  "/information/first-aid-kit/failure";
export const INFORMATION_FAK_GUILT_PATH = "/information/first-aid-kit/guilt";
export const INFORMATION_FAK_LOW_SELF_ESTEEM_PATH =
  "/information/first-aid-kit/low-self-esteem";
export const INFORMATION_FAK_RUMINATION_PATH =
  "/information/first-aid-kit/rumination";
export const INFORMATION_FAK_SOLITUDE_PATH =
  "/information/first-aid-kit/solitude";
export const INFORMATION_FAK_LOSS_AND_TRAUMA_PATH =
  "/information/first-aid-kit/loss-and-trauma";
export const PLAN_RESPONSE_PATH = "/plan-response";
export const INVITATION_CODE_PATH = "/invitation-code";
export const ADMIN_PATH = "/client-list";
export const PRIVACY_POLICY_FR_PATH = "/fr/privacy-policy";
export const PRIVACY_POLICY_EN_PATH = "/en/privacy-policy";
export const LEGAL_NOTICE_FR_PATH = "/fr/legal-notice";
export const LEGAL_NOTICE_EN_PATH = "/en/legal-notice";
export const TERMS_CONDITIONS_FR_PATH = "/fr/terms-conditions";
export const TERMS_CONDITIONS_EN_PATH = "/en/terms-conditions";
