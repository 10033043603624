import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import LogoFace from 'static/logo_face.png'
import { withNamespaces } from 'react-i18next'

// Styles
const rootStyle = {
    order: '1',
    width: '100%',
    flex: '13',
    overflowY: 'scroll',
    justifyContent: 'center',
    display: 'flex'
};

const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20%'
};

const imageStyle = {
    marginBottom: '15px'
}


class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(err, info) {
        this.setState({ hasError: true });
        Sentry.captureException(err);
    }

    render() {
        const { t } = this.props;

        if (this.state.hasError) {
            return (
                <div style={rootStyle}>
                    <div style={contentStyle}>
                        <img src={LogoFace} alt="logo face" style={imageStyle} />
                        <h1>{t('smtgWentWrong')}</h1>
                        <h3>
                            {t('reportTransmitted')}
                        </h3>
                        <h3>
                            {t('reloadPage')}
                        </h3>
                    </div>
                </div>
            )
        }
        return this.props.children;
    }
}

export default withNamespaces('errorBoundary')(ErrorBoundary)