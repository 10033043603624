import React, { Fragment } from 'react'
import { compose, renderNothing, branch } from 'recompose'
import { size } from 'lodash'
import { connect } from 'react-redux'
import * as actions from '../actions'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import classNames from 'classnames'
import styles from './Snackbars.styles'
import { withStyles } from '@material-ui/core/styles'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const Snackbars = ({ allIds, byId, dismissSnackbar, classes, className }) => {
  return (
    <Fragment>
      {allIds.map(id => {
        const Icon = variantIcon[byId[id].type]

        return (
          <Snackbar
            key={id}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            open>
            <SnackbarContent
              className={classNames(classes[byId[id].type], className)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <Icon
                    className={classNames(classes.icon, classes.iconVariant)}
                  />
                  {byId[id].message}
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => dismissSnackbar(id)}>
                  <CloseIcon className={classes.icon} />
                </IconButton>
              ]}
              classes={{
                message: classes.messageContainer,
                action: classes.actionContainer
              }}
            />
          </Snackbar>
        )
      })}
    </Fragment>
  )
}

const enhance = compose(
  connect(
    ({ snackbars: { allIds, byId } }) => ({ allIds, byId }),
    actions
  ),
  branch(props => !size(props.allIds), renderNothing), // only render if snackbars exist
  withStyles(styles)
)

export default enhance(Snackbars)
