import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import ButtonBase from '@material-ui/core/ButtonBase'
import { TERMS_CONDITIONS_EN_PATH } from 'constants/paths'
import { Link } from 'react-router-dom'
import EnglishFlag from './../../../../../static/flag_united_kingdom.png'

const TermsConditionsPage = ({ classes }) => (
  <div className={classes.root}>
    <div>
      <ButtonBase
        component={Link}
        to={TERMS_CONDITIONS_EN_PATH}
        className={classes.image}>
        <span
          className={[classes.imageSrc]}
          style={{
            backgroundImage: `url(${EnglishFlag})`
          }}
        />
        <span className={classes.imageBackdrop} />
      </ButtonBase>
    </div>
    <Paper className={classes.paper}>
      <h2 className={classes.title}>Conditions générales d'utilisation</h2>
      <h3>1. Informations légales et champ d'application</h3>
      <h4>1.1. Informations légales</h4>
      <p>
        Le site “ Learning From Emotions” (ci-après le “ Site ”), qui est
        accessible à l'adresse{' '}
        <a href="https://www.lfeapp.com">https://www.lfeapp.com</a>, et nos
        applications sont créés, développés et exploités par la société Learning
        and Development Business Partners, société par action simplifiée de
        droit français, au capital social de EUR 20 000, dont le siège social
        est situé au 67 rue Saint Jacques 75005 Paris, immatriculée auprès du
        Registre de Commerce et des Sociétés de Paris sous le numéro B 832 724
        272, et qui a pour numéro de TVA FR 05 832724272 (ci-après “ L&D BP” ou
        “ nous ”). Nous sommes joignables par téléphone au +33 (0)1 49 11 46 65
        et par email :<a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>
        .
      </p>
      <h4>1.2. Champ d'application</h4>
      <p>
        Les présentes conditions générales d'utilisation (les “ Conditions ”)
        s'appliquent à tout utilisateur du Site ou des applications.
      </p>
      <p>
        Elles ont notamment pour objet de définir les modalités de mise à
        disposition du Site et des applications ainsi que de leurs conditions
        d'utilisation.
      </p>
      <p>
        Tout accès et/ou utilisation du Site ou des applications suppose
        l'acceptation et le respect de l'ensemble de ces Conditions. Lisez avec
        attention les Conditions avant de faire usage du Site ou des
        applications. Si vous n'acceptez pas l'une quelconque des dispositions
        des présentes conditions, n'utilisez pas le Site ou les applications.
      </p>
      <h4>1.3. Modification</h4>
      <p>
        Nous nous réservons le droit de réviser et de modifier ces Conditions de
        temps à autre sans préavis notamment pour refléter les changements des
        conditions du marché affectant nos activités, les changements
        technologiques, les changements dans les méthodes de paiement, les
        changements dans les lois, les exigences réglementaires et les
        changements dans les capacités de nos systèmes. Nous vous informerons
        par avance de toute modification apportée à ces termes et vous pourrez
        choisir d'annuler votre inscription avant que les conditions modifiées
        n'entrent en vigueur.
      </p>
      <h3>2. Configuration requise</h3>
      <p>
        L'utilisation du Site et des applications nécessite une connexion
        Internet haut débit pour les ordinateurs personnels et un service
        Internet mobile (3G, 4G, 5G, Wifi) pour les appareils portables. Ces
        connexions ne sont pas fournies par L&D BP, l'utilisateur doit donc
        d'abord souscrire individuellement à une connexion Internet haut débit
        et / ou une offre internet pour mobile afin d'utiliser Site et
        l'application. Une connexion à Internet pour mobile dite de quatrième
        génération (4G) est fortement recommandée.
      </p>
      <p>
        En tant qu'utilisateur, vous pouvez activer le service depuis un
        ordinateur personnel (PC ou MAC) avec un système d'exploitation et un
        navigateur compatible et depuis un appareil mobile compatible (hardware,
        système d'exploitation, navigateur compatibles). Pour profiter du Site
        et de ses services via votre smartphone ou tout autre appareil, votre
        appareil doit satisfaire à certaines exigences relatives à leur système.
        Ces exigences peuvent être trouvés sur le Site et les appstores.
      </p>
      <h3>3. Accès et utilisation</h3>
      <h4>3.1. Création d'un compte</h4>
      <p>
        Afin d'accéder aux services du Site et des applications, il faut
        souscrire un abonnement. Vous devez ensuite créer un compte via la
        rubrique Inscription de la page d'accueil et remplir le formulaire où
        vous ferez figurer, selon les indications qui vous seront demandées les
        informations obligatoires pour la prise en compte de votre inscription,
        à savoir notamment en saisissant une adresse e-mail valide, en
        choisissant un mot de passe et en ajoutant le code d'invitation (remis
        lors de la souscription de l'abonnement) puis en validant l'inscription
        après avoir préalablement accepté les présentes Conditions.
      </p>
      <p>
        A l'occasion de vos visites ultérieures, vous pourrez accéder aux
        services en saisissant votre adresse e-mail et votre mot de passe tels
        qu'enregistrés lors de votre inscription.
      </p>
      <p>
        Dans le cas d'un oubli de mot de passe, vous disposez d'une fonction
        pour le réinitialiser sur le Site et sur les applications.
      </p>
      <p>
        Chaque utilisateur ne peut créer qu'un seul compte. Si nous désactivons
        votre compte, vous ne chercherez pas en créer un autre sans notre
        autorisation.
      </p>
      <h4>3.2. Identifiant et mot de passe</h4>
      <p>
        L'identifiant et le mot de passe que vous avez choisis lors de la
        création de votre compte vous sont propres, personnels et confidentiels.
      </p>
      <p>
        Vous vous engagez à les conserver secrets et à ne pas les divulguer sous
        quelque forme que ce soit. La conservation, l'utilisation et la
        transmission de votre identifiant et de votre mot de passe s'effectuent
        sous votre entière et unique responsabilité. Plus précisément, vous êtes
        averti de l'insécurité inhérente à l'utilisation de la fonction de
        mémorisation automatique des données de connexion que votre système
        informatique peut permettre, et déclare assumer l'entière responsabilité
        de l'utilisation et des conséquences éventuelles d'une telle fonction.
      </p>
      <p>
        Toute utilisation de votre identifiant et de votre mot de passe sera
        présumée avoir été effectuée ou dûment autorisée par vous.
      </p>
      <p>
        Par conséquent, nous ne saurions en aucun cas être tenu responsable des
        conséquences préjudiciables résultant des utilisations illicites,
        frauduleuses ou abusives de votre identifiant et de votre mot de passe
        et de l'accès au Site par un tiers non expressément autorisé par vous
        qui résulteraient de votre faute ou de votre négligence.
      </p>
      <p>
        En cas de vol ou de détournement de votre identifiant et de votre mot de
        passe, il vous appartient de nous avertir immédiatement par téléphone ou
        email.
      </p>
      <p>
        En cas d'impossibilité de connexion avec votre identifiant et votre mot
        de passe, vous devez nous avertir sans délai par téléphone ou email.
      </p>
      <p>
        Vous ne pouvez pas transférer votre compte à quiconque sans d'abord
        obtenir notre permission écrite, et vous interdisez d'usurper ou de
        tenter d'usurper l'identité d'autrui.
      </p>
      <h4>3.3. Services</h4>
      <p>
        Une fois votre compte créé, vous devenez membre de notre Site et de nos
        applications pour la durée d'activation donnée par votre code
        d'invitation. Ce qui vous donne accès à l'ensemble des sections, aux
        fonctionnalités et fonctions du site Web et de nos applications. Vous
        acceptez de recevoir des communications relatives à votre cycle de vie
        d'utilisateur, des messages à caractère technique ou relatif à la
        qualité de service de notre plateforme.
      </p>
      <p>L&D BP propose des abonnements trimestriels ou annuels.</p>
      <p>
        Pour les besoins de nos abonnements trimestriels et annuels, un
        trimestre est fait de 90 jours civils et une année est constituée de 365
        jours.
      </p>
      <p>
        Le bénéficiaire du code d'activation peut effectivement accéder aux
        services de Learning From Emotions (version adéquate de smartphone,
        tablette, ordinateur, système d'exploitation, navigateur, ou
        application) dès que l'inscription est réalisée. En cas de souci, il
        peut contacter L&D BP d'un problème d'inscription par email :
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>
      </p>
      <p>
        Aucun remboursement ne peut être demandé auprès de L&D BP après
        l'activation du code d'invitation.
      </p>
      <p>
        L'obligation de fourniture des services ne commence qu'après la saisie
        d'un code d'invitation valide et les autres informations obligatoires.
        L'accès aux services est confirmé par email.
      </p>
      <h4>3.4. Garanties</h4>
      <p>
        En créant un compte sur le Site ou sur l'application et en utilisant les
        services, vous certifiez, vous garantissez et vous vous engagez à ce que
        :
      </p>
      <ul>
        <li>
          Les informations que vous fournissez sont personnelles, sont exactes
          et que vous n'utilisez pas les informations personnelles d'autrui sans
          autorisation
        </li>
        <li>
          Vous êtes légalement capable de conclure des engagements juridiques
        </li>
        <li>
          Vous êtes âgé d'au moins 13 ans et si vous êtes âgé de moins de 18 ans
          (ou de l'âge de la majorité selon la loi qui vous est applicable),
          vous créez votre compte et vous vous engagez sous la direction, la
          supervision et avec le consentement de vos représentants légaux
        </li>
        <li>Vos informations personnelles seront mises à jour si nécessaire</li>
      </ul>
      <h3>4. Réclamation, résiliation et droit de rétractation</h3>
      <h4>4.1. Réclamation</h4>
      <p>
        Vous pouvez adresser vos réclamations par email à l'adresse :{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>
      </p>
      <h4>4.2. Résiliation de votre part</h4>
      <p>
        Vous pouvez à tout moment résilier votre abonnement trimestriel ou
        annuel, en respectant un délai de préavis d'au moins 48heures avant
        l'échéance en cours, en adressant un email à l'adresse :
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>. La
        résiliation sera effective à la fin de la période trimestrielle ou
        annuelle, en cours.
      </p>
      <h4>4.3. Résiliation par nous</h4>
      <p>
        Nous pouvons suspendre ou résilier, sans préavis ni recours aux
        Tribunaux, votre utilisation de nos service la suite d'une fraude ou de
        manquement à une obligation découlant de ces Conditions, y incluant sans
        limitation, la copie non autorisée ou le téléchargement de nos contenus
        à partir du Site ou de l'application ou de toute violation de la licence
        d'utilisation limitée que nous vous concédons dans le cadre de votre
        accès à nos services.
      </p>

      <h3>5. Propriété intellectuelle et licence d'utilisation</h3>
      <h4>5.1. Propriété intellectuelle</h4>
      <p>
        L'ensemble du Site et des applications en y incluant leurs structures,
        arborescences, graphismes, codes objet ou source ainsi que leurs
        contenus (notamment textes, graphiques, images, photographies, vidéos,
        informations, logos, icônes-boutons, logiciels, fichiers audio et
        autres, bases de données) est la propriété de L&D BP, ou de leurs
        propriétaires respectifs et est protégé par la propriété intellectuelle,
        notamment par le droit d'auteur et le droit des marques. La dénomination
        “ Learning From Emotions” et le logo “ Learning From Emotions ” sont
        notamment des marques protégées.
      </p>
      <p>
        Nous nous réservons tout droit sur le Site et les applications qui ne
        vous serait pas expressément concédé aux termes des présentes
        Conditions. En conséquence, vous vous interdisez d'utiliser le Site à
        d'autres fins que celles visées ci-dessous, en l'absence de notre accord
        exprès préalable.
      </p>
      <p>
        Tout manquement à ce qui précède est susceptible d'engager votre
        responsabilité, sans préjudice de notre droit de résilier
        unilatéralement votre abonnement conformément aux termes de l'article
        4.3 ci-dessus.
      </p>
      <h4>5.2. Licence d'utilisation</h4>
      <p>
        Sous réserve du respect des présentes Conditions, et en fonction des
        abonnements souscrits, nous vous autorisons à accéder et à utiliser le
        Site et aux applications. La licence qui vous est ainsi concédée est
        strictement personnelle, non exclusive, limitée et révocable en cas de
        non-respect des présentes Conditions. Nous attirons en particulier votre
        attention sur le fait qu'aucune cession de droit de propriété
        intellectuelle ne peut être inféré de votre utilisation du Site et des
        applications, y compris à l'égard des éléments que nous vous autorisons
        à télécharger en application des présentes.
      </p>
      <p>
        Sauf exceptions spécifiées ci-après, il vous est strictement interdit
        d'effectuer ou d'aider un tiers à effectuer tout ou partie des actions
        suivantes :
      </p>
      <ul>
        <li>
          Copier, stocker, reproduire, transmettre, modifier, altérer,
          désassembler, imiter, décompiler ou désassembler le Site ou les
          applications, ou créer des œuvres dérivées à partir de ceux-ci
        </li>
        <li>
          Modifier tout ou partie du site Web, des services ou de leur contenu
          ou contourner toute technologie utilisée par L & D BP ou ses
          concédants de licence afin de protéger tout le contenu accessible via
          le site et ses services
        </li>
        <li>
          Contourner les mesures de sécurité du Site et des applications pour
          accéder à une partie ou une fonctionnalité non autorisée
        </li>
        <li>
          Contourner les restrictions territoriales appliquées au Site et aux
          services ou plus généralement
        </li>
        <li>
          Utiliser les services d'une manière qui viole cet accord de licence ou
          les autres Conditions
        </li>
      </ul>
      <p>
        Par exception à ce qui précède, une quantité limitée de contenu sera
        autorisé (et précisé comme tel, le cas échéant pendant une durée
        limitée) à être partagé par l'utilisateur sur ses réseaux sociaux
        personnels. L&D BP vous accorde un droit limité pour télécharger,
        reproduire et distribuer le Contenu de distribution sur Internet
        uniquement aux fins de partage et/ou commentaires sur vos réseaux
        sociaux, sites et/ou pages internet personnels, ainsi qu'aux conditions
        cumulatives suivantes : (i) le Contenu de distribution ne peut en aucun
        cas être modifié, sous réserve des modifications techniques
        indispensables à son affichage et sa distribution à travers vos systèmes
        informatiques et sur Internet (par exemple un changement de format vidéo
        ou la taille du fichier) n'altérant ni le fond ni la qualité du Contenu
        de distribution, (ii) le Contenu de distribution ne peut en aucun cas
        être partagé en dehors de la durée susceptible d'être indiquée sur le
        Site, (iii) les conditions de partage du Contenu de distribution doivent
        être conformes aux conditions additionnelles susceptibles d'être
        indiquées au cas par cas sur le Site , (iv) le Contenu de distribution
        ne doit en aucun cas être diffusé avec un un autre contenu que vous
        savez être faux, inexacte ou trompeur ou qui est, ou qui encourage
        l'activité ou des comportements qui sont illégaux, nuisibles, menaçants,
        abusifs, harcelants, tortueux, diffamatoires, vulgaires, obscènes,
        pornographiques, diffamatoires, irrespectueux de la vie privée d'autrui,
        haineux, raciste, ou autrement répréhensibles. Ce Contenu de
        distribution peut contenir des gifs ou des cookies qui nous permettent
        de recueillir des informations concernant la distribution et la
        consommation de ces contenus. Pour plus d'informations, veuillez
        consulter notre Politique de confidentialité.
      </p>
      <h3>6. Utilisations interdites du Site et des services</h3>
      <h4>
        6.1. Atteinte à l'intégrité, la sécurité et au fonctionnement du Site et
        des applications
      </h4>
      <p>
        Vous vous interdisez de télécharger, publier, envoyer par courriel,
        transmettre ou introduire sur notre plateforme tout matériel ou fichier
        qui contient des virus informatiques ou tout autre code informatique,
        Les fichiers ou programmes conçus pour interrompre, abimer, détruire ou
        limiter la fonctionnalité de tout logiciel ou matériel informatique ou
        équipement lié directement ou indirectement avec nos services ou de nos
        services eux-mêmes. Vous acceptez de ne pas interférer avec les serveurs
        ou les réseaux sous-jacents ou connexes au site Web ou aux autres
        services ou de ne pas enfreindre les procédures, politiques ou
        règlements des réseaux connectés à notre site Web ou à nos autres
        services.
      </p>
      <p>
        Il vous est interdit de sonder, scanner ou tester la vulnérabilité du
        Site ou des applications, de violer les mesures de sécurité ou
        d'authentification de ceux-ci., ou encore de chercher à tracer, ou
        récupérer toute information sur tout autre utilisateur du Site ou des
        applications.
      </p>
      <p>
        Vous ne pouvez en aucun cas interférer ou tenter d'interférer avec
        l'accès de tout utilisateur, hôte ou réseau, y compris, sans limitation,
        l'envoi d'un virus, l'"overloading", le " flooding", le spamming, ou du
        "mail bombing" de nos services.
      </p>
      <p>
        Vous vous interdisez toute action qui impose une charge déraisonnable ou
        disproportionnée sur l'infrastructure des Site, systèmes ou réseaux, des
        Applications de L&D BP, ou des systèmes ou réseaux connectés au Site et
        aux applications, ou qui est de nature à interférer avec le bon
        fonctionnement du Site ou des applications, avec toute transaction
        effectuée sur ceux-ci, ou avec l'utilisation de toute autre personne.
      </p>
      <p>
        Vous vous interdisez d'utiliser de "deep-link", de "page-scrape",
        "robot", "spider", ou autre dispositif automatique, programme,
        algorithme ou méthodologie ou tout processus manuel similaire ou
        équivalent, pour accéder, acquérir, copier ou surveiller toute partie du
        Site et des applications, , ou vous ne tenterez en aucune manière de
        reproduire ou de contourner la structure de navigation ou la
        présentation des sites Web, les applications, ou de tout contenu,
        d'obtenir ou de tenter d'obtenir du matériel, des documents ou
        informations par des moyens non disponibles à cet effet sur les sites
        Web ou les applications.
      </p>
      <p>
        Il vous est en outre interdit d'accéder ou de tenter d'accéder à
        n'importe quel serveur Learning From Emotions, ou à l'un des services
        offerts sur ou par l'intermédiaire du Site et des applications, par
        piratage, "password mining", ou utilisation d'autres moyens illégitimes.
      </p>
      <h4>6.2. Interactions sur le Site et les services</h4>
      <p>
        De manière générale, vous vous engagez à ne pas vous conduire d'une
        manière répréhensible lorsque vous utilisez nos services, ou de ne pas
        utiliser les services à des fins illégales, immorales ou nuisibles.
      </p>
      <p>
        Vous êtes seul responsable des messages, contenus et informations
        publiées par vos soins sur le Site dès lors qu'en sa qualité d'hébergeur
        du service ici considérée, L&D BP ne saurait être responsable du contenu
        publié par les membres sur le Site et sur lesquels L&D BP n'exerce aucun
        pouvoir de contrôle et de surveillance.
      </p>
      <h3>7. Disponibilité des services</h3>
      <p>
        Bien que nous nous efforcions de vous offrir le meilleur service
        possible, nous ne faisons pas la promesse que les services seront
        toujours disponibles et répondront à vos besoins, attentes et à la
        charge ; nous ne pouvons garantir que les services seront sans défaut.
        Si une erreur se produit dans les services, merci de nous le signaler à
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a> et nous
        essaierons de le corriger la faute dans des délais raisonnable. Si le
        besoin s'en fait sentir, nous pouvons suspendre l'accès aux services
        alors que nous essayons de régler le problème. Nous ne pourrons pas être
        tenus responsables envers vous si les services ne sont pas disponibles
        pour une période de temps.
      </p>
      <p>
        Votre accès aux services peut être occasionnellement restreint pour
        permettre des réparations, l'entretien ou la mise en place de nouvelles
        installations ou services. Nous essaierons de rétablir les services dès
        qu'il sera raisonnablement possible. Dans le cas où les services ne sont
        pas disponibles, nos délais et dates habituels de commandes et
        d'annulation s'appliquent normalement ; merci de nous informer des
        changements relatifs à votre commande via notre email :{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>{' '}
      </p>
      <p>
        Le contenu est susceptible d'évolution. Nous nous réservons la
        possibilité de supprimer, d'ajouter ou de modifier des fonctionnalités
        sans préavis et sans recours de votre part.
      </p>
      <h3>8. Liens hypertextes</h3>
      <h4>8.1. Lien vers d'autres sites</h4>
      <p>
        Il est possible que nous vous fournissions des liens vers d'autres sites
        ou services d'accès. Vous reconnaissez que l'accès à ces sites ou
        services est à votre seule discrétion et que ces liens ne sont présents
        que pour votre information seulement. Nous n'avons pas analysé, revu, ni
        ne soutenons aucun de ces sites Web ou services. Nous ne pouvons pas
        être tenus responsables d'aucune façon pour : (a) la disponibilité, (b)
        les pratiques de protection des données personnelles, (c) le contenu, la
        publicité, les produits, les biens ou d'autres matériaux ou de
        ressources disponibles sur ou à partir de ces sites/ services, ou (d)
        l'usage de ce que d'autres font de ces sites et services. Nous ne sommes
        pas responsables de tout dommage, perte ou délit causé ou prétendument
        causé par, ou en relation avec l'utilisation ou avec la confiance que
        vous accordez à ces sites ou services.
      </p>
      <h4>8.2. Lien vers le Site</h4>
      <p>
        Vous pouvez créer un lien vers notre page d'accueil, à condition que
        vous le fassiez d'une manière qui est juste et légale et qui n'endommage
        pas notre réputation ou en profite, mais vous n'avez pas le droit
        d'établir un lien de telle manière à suggérer une quelconque forme
        d'association, d'approbation ou d'endossement de notre part qui n'existe
        pas. Vous ne devez pas créer un lien vers notre Site sur un site Web qui
        n'est pas détenue par vous. Notre Site et nos autres services ne doivent
        pas être encadrés ou encapsulés dans un autre site ; vous ne pouvez pas
        non plus créer un lien vers n'importe quelle partie de notre Site ou de
        nos autres services, sauf si vous avez la permission écrite de L&D BP de
        le faire. Nous nous réservons le droit de retirer l'autorisation via un
        avis écrit en cas d'usage portant atteinte à notre image ou à notre
        réputation. Le Site à partir duquel vous liez le nôtre doit se conformer
        en tous points aux normes de contenu définies dans les présentes
        Conditions, et notamment à l'article 6.2 ci-dessus. Si vous souhaitez
        faire une autre utilisation des informations disponibles sur notre site
        web ou nos autres services que celle indiquée ci-dessus, merci
        d'adresser votre demande à{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>
      </p>
      <h3>9. Limitation de responsabilité</h3>
      <p>
        Nous mettons tous les moyens raisonnables à notre disposition pour
        assurer un accès de qualité au Site et aux applications ainsi que la
        sécurité des données que vous nous transmettez via le Site ou les
        applications, mais n'assumons aucune obligation de résultat à cet égard.
      </p>
      <p>
        Nous ne garantissons pas le fonctionnement continu du Site ou des
        applications qui peuvent notamment être interrompu pour des raisons de
        maintenance.
      </p>
      <p>
        Nous ne pouvons être tenus responsable de tout autre dysfonctionnement
        ou défaillance du réseau ou des serveurs ou de tout autre
        dysfonctionnement technique échappant à notre contrôle raisonnable qui
        empêcherait ou altérerait l'accès à tout ou partie du Site ou des
        applications, ainsi qu'en cas de force majeure telle que définie par la
        réglementation applicable.
      </p>
      <p>
        Nous attirons plus particulièrement votre attention sur les limitations
        et contraintes propres au réseau internet et, l'impossibilité de
        garantir de manière totale la sécurisation des échanges de données. Il
        vous appartient à ce titre de prendre toutes les mesures nécessaires
        pour vous protéger contre les intrusions non autorisées au sein de votre
        système d'information, notamment en sécurisant votre connexion internet
        par un mot de passe ou en utilisant les logiciels anti-virus appropriés.
      </p>
      <p>
        Par ailleurs, vous êtes informés que la qualité du fonctionnement du
        Site et des applications, notamment des temps de réponse, sont
        susceptibles de varier en fonction des paramètres personnels de votre
        poste de connexion et de votre service d'accès au réseau Internet. Nous
        n'assumons aucune responsabilité à cet égard.
      </p>
      <p>
        En conséquence, nous ne saurions en aucun cas voir notre responsabilité
        engagée, directement ou indirectement, du fait des dysfonctionnements du
        Site et des applications, y incluant toute perte de données sauvegardées
        par sur votre compte, causés par une ou plusieurs des caractéristiques
        techniques inhérentes au réseau Internet, ou au matériel informatique ou
        aux logiciels utilisés par vos soins, et ce pour quelque raison que ce
        soit.
      </p>
      <p>
        De même, aucun dysfonctionnement du Site ou des applications, y incluant
        toute perte de données sauvegardées par sur votre compte, ne pourra nous
        être reproché dès lors qu'un tel dysfonctionnement résulterait d'une
        mauvaise utilisation du Site ou des applications par vos soins, ou de
        l'inadéquation du matériel et des logiciels informatiques que vous
        utilisez au regard des spécifications techniques visées ci-dessus.
      </p>
      <p>
        Les informations mises à disposition sur le site ou les applications le
        sont uniquement à titre purement informatif et ne sauraient constituer
        en aucun cas un conseil ou une recommandation de quelque nature que ce
        soit.
      </p>
      <p>
        En conséquence, l'utilisation des informations et contenus disponibles
        sur tout ou partie du Site ne saurait en aucun cas engager notre
        responsabilité, à quelque titre que ce soit.
      </p>
      <p>
        Dans la limites des dispositions légales impératives applicables, en
        aucun cas, y compris mais non limité en cas de négligence, L&D BP ou ses
        sociétés affiliées, fournisseurs, clients ou fournisseurs
        (collectivement, les “ entités protégées ”) ne pourront être tenues
        responsables des dommages indirects, particuliers, accessoires,
        consécutifs, exemplaires ou punitifs découlant de, ou liés directement
        ou indirectement à l'utilisation ou l'incapacité d'utiliser les services
        ou le contenu, le matériel et les fonctions qui lui sont associée, votre
        fourniture d'informations par les services, ou perte d'activité ou la
        perte de ventes, ou des erreurs, virus ou de bogues contenus dans les
        services, même si une telle entité protégée a été informé de la
        possibilité de tels dommages. En aucun cas, les entités protégées ne
        peuvent être tenues responsables pour ou en relation avec tout contenu
        affiché, transmis, échangé ou reçu par ou pour le compte d'un
        utilisateur ou d'une autre personne ou par l'intermédiaire des services.
        En aucun cas, la responsabilité globale des entités protégées à votre
        égard pour tous dommages, pertes et suites d'évènements et d'actions
        (contractuelle ou délictuelle, y compris, mais sans s'y limiter, la
        négligence ou autre) découlant de ces Conditions ou de votre utilisation
        des services ne dépassera, au total, la somme la somme de 50 (cinquante)
        euros.
      </p>
      <p>
        Rien dans la présente clause n'est de nature à limiter la responsabilité
        de L&D BP ou des entités protégées en case de dol, faute lourde ou de
        dommages corporels.
      </p>
      <h3>10. Avertissement médical</h3>
      <p>
        L&D BP est un fournisseur d'abonnements en ligne et mobiles pour accéder
        à du contenu relatif aux émotions. Nous ne sommes pas un dispositif
        médical, nous ne fournissons pas des conseils médicaux. Tous les
        utilisateurs ayant de graves problèmes médicaux ou psychologiques ou
        nécessitant un avis médical doivent consulter leur médecin. Toutes les
        informations et liens depuis nos services relatifs à la santé, qu'ils
        soient fournis par L&D BP ou par des prestataires extérieurs
        contractuels, sont fournis uniquement pour votre information et votre
        commodité.
      </p>
      <p>
        Tous les conseils ou autres éléments disponibles dans nos services sont
        destinés uniquement à des fins d'information générale. Ils ne sont pas
        destinés à servir de référence et de substitut à un avis médical
        professionnel basé sur votre situation personnelle. Les conseils et les
        autres éléments du site sont destinés à aider la relation entre vous et
        vos fournisseurs de soins et de santé mais pas à la remplacer. Nous ne
        pouvons pas être tenus responsables de toute mesure ou action prise en
        raison de ou après avoir lu ou entendu nos conseils et contenus ou après
        avoir utilisé nos services. En particulier, dans la mesure où cela est
        permis par la loi, nous ne donnons aucune garantie quant à l'exactitude,
        l'exhaustivité ou la pertinence (pour quoi que ce soit) d'un avis et
        d'une information publiée dans le cadre nos services.
      </p>
      <h3>11. Données personnelles</h3>
      <p>
        Les données à caractère personnel ainsi recueillies sur le Site et les
        applications sont traitées conformément à la loi du 20 juin 2018
        relative à la protection des personnes à l'égard du traitement des
        données à caractère personnel. Vous disposez d'un droit d'accès et de
        rectification aux données vous concernant, vous adressant à{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>
      </p>
      <p>
        L&D BP se réserve, dans l'hypothèse où le membre se rendrait responsable
        d'une infraction à la législation en vigueur ou d'une atteinte aux
        droits des tiers, le droit de fournir, à la demande de toute autorité
        légitime (juridiction, autorité administrative, services de police),
        tous renseignements permettant ou facilitant l'identification du membre
        contrevenant.
      </p>
      <p>
        Pour plus d'informations, merci de consulter notre “ Politique de
        confidentialité́ ” qui est accessible en ligne et qui fait partie
        intégrante des présentes Conditions.{' '}
      </p>
      <h3>12. Dispositions générales</h3>
      <h4>12.1. Cession</h4>
      <p>
        L&D BP peut transférer ses droits et obligations en vertu de ces
        Conditions à toute société, entreprise ou personne à tout moment si cela
        ne modifie pas sensiblement vos droits concernés. Vous ne pouvez pas
        transférer vos droits ou obligations en vertu de ces Conditions à
        quelqu'un d'autre, sauf accord préalable et écrit de L&D BP.
      </p>
      <h4>12.2. Garantie et indemnités</h4>
      <p>
        Vous acceptez de défendre, d'indemniser et de soutenir L&D BP et ses
        administrateurs, dirigeants, membres, investisseurs, dirigeants,
        employés et agents de toute réclamation, invocation de la
        responsabilité, coûts et dépenses, y compris les honoraires raisonnables
        d'avocat, qui pourraient découler de quelque façon de votre utilisation
        des services, de votre publication ou transmission de tout message,
        contenu, informations, logiciels ou autres communications à travers nos
        services, ou de votre violation de la loi ou de ces termes. L&D BP se
        réserve le droit, à ses propres frais, d'assumer la défense exclusive et
        le contrôle de toute affaire par ailleurs susceptible de nécessiter une
        indemnisation par vous, et dans ce cas, vous acceptez de coopérer avec
        la défense de L&D BP d'une telle réclamation.
      </p>
      <h4>12.3. Aucune renonciation</h4>
      <p>
        Si nous retardons l'exercice ou ne parvenons pas à exercer ou à
        appliquer tout droit à notre disposition en vertu de ces Conditions, un
        tel retard ou l'échec ne constitue pas une renonciation à ce droit ou à
        d'autres droits en vertu de ces termes.
      </p>
      <h4>12.4. Force majeure</h4>
      <p>
        Nous ne serons pas responsables envers vous pour tout manque de
        performance, indisponibilité ou panne des services, ou pour tout
        manquement ou retard de notre part pour se conformer à ces conditions,
        dans le cas où ce manque de performance, indisponibilité ou panne est dû
        à une cause hors de notre contrôle raisonnable.
      </p>
      <h4>12.5. Interprétation</h4>
      <p>
        Dans ces Conditions, à moins que le contexte ne s'y oppose : i) une
        phrase introduite par les mots "y compris", "comprend", "en
        particulier", "par exemple" ou toute expression similaire doit être
        interprétée comme illustration seulement et ne doit pas être interprétée
        comme limiter la généralité de tous les mots qui précèdent ; et ii) les
        références au singulier comprennent le pluriel et le masculin inclut le
        féminin, et dans chaque cas vice versa.
      </p>
      <h4>12.6. Communications écrites</h4>
      <p>
        Les lois applicables exigent que certaines des informations ou
        communications que nous vous envoyons se fassent par écrit. Lorsque vous
        utilisez le Site et les applications, vous acceptez que les
        communications avec nous soient principalement électroniques. Nous vous
        contacterons par e-mail ou vous fournirons des informations en publiant
        sur le Site des avis sur les services. Vous acceptez ces moyens de
        communications électroniques et vous reconnaissez que tous les contrats,
        avis, informations et autres communications que nous vous fournissons
        électroniquement ont la même valeur que les communications faites par
        écrit.
      </p>
      <h4>12.7. Preuve / Archivage des preuves</h4>
      <p>
        Les données informatiques générées en raison de l'accès et/ou de
        l'utilisation du Site et les applications (y incluant, notamment, les
        programmes, données de connexion, fichiers, enregistrements, opérations
        et autres éléments sur support informatique ou électronique) (les “
        Données ”) sont susceptibles d'être conservées et archivées, dans la
        limite prévue par la loi, par nos soins au sein de notre système
        informatique, dans des conditions de nature à en garantir l'intégrité.
      </p>
      <p>
        Les Données font foi entre vous et nous, et seront considérées comme les
        preuves des communications entre vous et nous.
      </p>
      <p>
        Les Données constituent une preuve littérale et ont la même force
        probante qu'un document qui serait établi, reçu ou conservé par écrit
        sur support papier.
      </p>
      <p>
        Par conséquent, les Données pourront être valablement produites comme
        moyens de preuve et vous être opposées dans le cadre de toute
        réclamation ou action en justice avec la même force probante que tout
        document qui serait établi, reçu ou conservé par écrit sur support
        papier, ce que vous reconnaissez expressément.
      </p>
      <p>
        Nous nous engageons à ne pas contester la recevabilité, l'opposabilité
        ou la force probante des Données en raison de leur nature électronique.
        Vous vous engagez de même.
      </p>
      <p>
        Vous pouvez avoir accès à l'ensemble des Données sur simple demande
        adressée par courrier simple ou par courrier électronique.
      </p>
      <h4>12.8. Divisibilité</h4>
      <p>
        Si un tribunal ou une autorité compétente décide que l'une des
        dispositions de ces Conditions n'est pas valide, ou est illégale ou
        inapplicable dans une certaine mesure, la disposition sera, dans cette
        mesure seulement, être séparé des autres dispositions, qui continueront
        à être valables dans toute la mesure permise par la loi.
      </p>
      <h4>12.9. Intégralité de l'accord</h4>
      <p>
        Ces Conditions, ainsi que les conditions supplémentaires éventuelles et
        tout autre document expressément nommé dans ces termes représentent
        l'intégralité de l'accord entre nous et remplacent toutes les
        précédentes discussions, correspondances, négociations, arrangements
        précédents, entente ou accord entre nous concernant l'objet de ces
        termes. Nous reconnaissons qu'aucun de nous se fonde sur, une
        déclaration ou une garantie (si faite involontairement ou par
        négligence) qui ne figurent pas dans ces termes ou les documents
        auxquels ils se réfèrent. Chacun d'entre nous accepte que notre seul
        recours à l'égard de ces déclarations et garanties tels qu'énoncés dans
        cet accord (si fait involontairement ou par négligence) sera la rupture
        de contrat. Rien dans cette section ne limite ou n'exclut la
        responsabilité en cas de fraude ou de vol.
      </p>
      <h4>12.10. Droit applicable</h4>
      <p>Les présentes Conditions sont soumises au droit français.</p>
      <p>
        Sous réserve des dispositions légales impératives applicables, les
        tribunaux parisiens seront exclusivement compétents pour connaître de
        tout litige lié à l'interprétation, l'application et l'exécution des
        présentes Conditions, ainsi que de tout litige lié à l'utilisation du
        Site ou des applications.
      </p>
      <p>
        Ces Conditions sont effectives à compter de leur dernière mise à jour le
        1er septembre 2019.
      </p>
    </Paper>
  </div>
)

TermsConditionsPage.propTypes = {
  classes: PropTypes.object // from enhancer (withStyles)
}

export default TermsConditionsPage
