import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import ButtonBase from '@material-ui/core/ButtonBase'
import { TERMS_CONDITIONS_FR_PATH } from 'constants/paths'
import { Link } from 'react-router-dom'
import FrenchFlag from './../../../../../static/flag_french.png'

const TermsConditionsPage = ({ classes }) => (
  <div className={classes.root}>
    <div>
      <ButtonBase
        component={Link}
        to={TERMS_CONDITIONS_FR_PATH}
        className={classes.image}>
        <span
          className={[classes.imageSrc]}
          style={{
            backgroundImage: `url(${FrenchFlag})`
          }}
        />
        <span className={classes.imageBackdrop} />
      </ButtonBase>
    </div>
    <Paper className={classes.paper}>
      <h2 className={classes.title}>Terms and Conditions</h2>
      <h3>1. Legal information and scope</h3>
      <h4>1.1. Legal Information</h4>
      <p>
        The site "Learning From Emotions" (hereinafter the "Site"), which is
        accessible at:{' '}
        <a href="https://www.lfeapp.com">https://www.lfeapp.com</a>, via our
        applications (hereinafter the "apps") or our partners' websites, is
        created, developed and operated by the company Learning and Development
        Business Partners SAS (L&D BP), a limited company under French law, with
        a shared capital of EUR 20,000, headquartered at 67 rue Saint Jacques
        75005 Paris, registered with the Trade and Business Registrar of Paris
        under number B 832 724 272, and having the VAT number FR 05 832724272
        (hereinafter "L&D BP" or "us"). You may contact us by telephone at +33
        (0)1 49 11 46 65 or by email:{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>.
      </p>
      <h4>1.2. Scope</h4>
      <p>
        These terms and conditions of use and sale (the "Terms") apply to any
        visitor ("you" or "user") of the Site or the Services.
      </p>
      <p>
        Their purpose is to define the terms for accessing the Site and the
        services available on the Site, as well as its terms of use.
      </p>
      <p>
        Any access and / or use of the Site implies the acceptance and respect
        of all Terms. Read the Terms carefully before using the Site or its
        services. If you do not agree to any of the provisions of these Terms,
        please do not use the Site.
      </p>
      <h4>1.3. Amendments</h4>
      <p>
        We reserve the right to revise and amend these Terms from time to time
        without notice in particular to reflect changes in market conditions
        affecting our business, technological changes, payment method changes,
        change in laws. We will notify you in advance of any changes to these
        terms and you may choose to cancel your subscription to our services,
        without penalty, before the amended terms come into effect.
      </p>
      <h3>2. System Requirements</h3>
      <p>
        The use of the Site and its services requires a high-speed Internet
        connection for personal computers and a mobile Internet service (3G, 4G,
        Wifi) for portable devices. These connections are not provided by L&D
        BP, so the user must first subscribe individually to a broadband
        Internet connection and / or a mobile internet provider in order to use
        Site and its services. A fourth generation (4G) mobile Internet
        connection is highly recommended.
      </p>
      <p>
        As a user, you may activate the service from a personal computer (PC or
        MAC) with a compatible operating system and browser and from a
        compatible mobile device (hardware, operating system, browser
        compatible). To enjoy the Site and its services via your smartphone or
        other device, your device must meet certain system requirements. These
        requirements can be found on the Site and the appstores.
      </p>
      <h3>3. Access and Use</h3>
      <h4>3.1. Creating an Account</h4>
      <p>
        In order to access the Site services and register for the corresponding
        subscriptions you must first create an account via the relevant Site by
        completing the registration form where you will fill in online as
        indicated and requested, and in the fields provided for this purpose,
        the information required to take into account your registration,
        including a valid email address, choosing a password, adding the
        invitation code and then confirming your registration.
      </p>
      <p>
        For your subsequent visits, you will be able to access the services by
        entering your e-mail address and your password as recorded during your
        registration.
        <p>
          If you forget your password, there is a feature to reset it on the
          Site.
        </p>
        <p>
          Each user may only create one account. If we disable your account, you
          may not try to create another without our permission.{' '}
        </p>
      </p>
      <h4>3.2. Login and password</h4>
      <p>
        The username and password you chose when creating your account are your
        own, personal and confidential.
      </p>
      <p>
        You agree to keep your username and password secret and not to disclose
        it in any form whatsoever. Storing, using and transmitting your username
        and password are your sole responsibility. Particularly, you are hereby
        notified of the inherent lack of security of using the auto-save feature
        for your login details that your computer system may allow, therefore,
        you declare that you are assuming full responsibility for using it, and
        any possible consequences thereof.
      </p>
      <p>
        Any use of your username and password will be deemed to have been made
        or duly authorized by you.
      </p>
      <p>
        Therefore, we may not in any way be held responsible for any harmful
        consequences resulting from unlawful, fraudulent or abusive use of your
        username and password and the access to the Site by a third party not
        expressly authorized by you that would result from your fault or
        negligence.
      </p>
      <p>
        In case of theft or misappropriation of your username and password, it
        is your responsibility to notify us immediately by telephone or email
        with acknowledgment of receipt.
      </p>
      <p>
        In the event it is impossible to login with your username and your
        password, you must inform us without delay by telephone or email.
      </p>
      <p>
        You may not transfer your account without first obtaining our written
        permission, and you may not impersonate or attempt to impersonate
        others.
      </p>
      <h4>3.3. Services</h4>
      <p>
        Once your account is created, you become a member of our Site, which
        gives you access to certain sections, features and functions of the
        website and our application. You also agree to receive emails related to
        your user lifecycle, and technical or quality of service
      </p>
      <p>L&D BP offers trimestral and yearly subscription :</p>
      <p>
        Regarding the trimestral and yearly, a trimester is made of 90 calendar
        days and one year of 365 days.
      </p>
      <p>
        The recipient of the invitation code can actually access the Learning
        From Emotions services (appropriate version of smartphone, tablet,
        computer, operating system, browser, or application) as soon as
        registration is completed. In case of concern, you can contact L&D BP
        with a registration problem by email:{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>.
      </p>
      <p>
        No refunds can be requested from L&D BP after activation of the
        invitation code.
      </p>
      <p>
        The obligation to provide services begins only after entering a valid
        invitation code and other mandatory information. Access to services is
        confirmed by email.
      </p>
      <h4>3.4. Guarantees</h4>
      <p>
        By creating an account on the Site and using the services, you certify,
        guarantee and agree that :
      </p>
      <ul>
        <li>
          the information you provide is personal, accurate and you are not
          using personal information of others without permission
        </li>
        <li>you are legally able to enter into legal commitments</li>
        <li>
          you are at least 13 years old and if you are under the age of 18 (or
          of adult age pursuant to the law applicable to you), you create your
          account and you are engaged under the direction, supervision and with
          the consent of your legal representatives
        </li>
        <li>your personal information will be updated if necessary</li>
      </ul>
      <h3>4. Complaints, Cancellation and Right of Withdrawal</h3>
      <h4>4.1. Complaints</h4>
      <p>
        You may send your complaints by email to:{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>
      </p>
      <h4>4.2. Termination on your part</h4>
      <p>
        You may terminate your trimestral or annual subscription at any time,
        with a notice period of at least 48 (forty-eight) hours before the
        current deadline. The termination will be effective at the end of the
        period, trimestral or annual, in progress.
      </p>
      <h4>4.3. Termination by us</h4>
      <p>
        We may suspend or terminate, without prior notice or recourse to the
        Courts, your use of our services as a result of fraud or breach of an
        obligation under these Terms, including without limitation, unauthorized
        copying or downloading of our audio or video content from the Site or
        the apps or any violation of the limited-use license that we grant you
        as part of your access to our services.
      </p>

      <h3>5. Intellectual Property and Use License</h3>
      <h4>5.1. Intellectual Property</h4>
      <p>
        The entire Site and its services including their structure, tree
        structure, graphics, object or source codes and their content (including
        text, graphics, images, photographs, videos, information, logos, button
        icons, software, audio files and others, databases) is the property of
        L&D BP, or their respective owners and is protected by intellectual
        property, including copyright and trademark law. The name "Learning From
        Emotions" and the " Learning From Emotions " logo are in particular
        protected trademarks.
      </p>
      <p>
        We reserve all rights to the Site that have not been expressly granted
        to you under these Terms. Accordingly, you are prohibited from using the
        Site for purposes other than those described below, in the absence of
        our express prior agreement.
      </p>
      <p>
        Any breach of the foregoing may incur your liability, without prejudice
        to our right to terminate your subscription unilaterally in accordance
        with Article 4.3. above.
      </p>
      <h4>5.2. Use License</h4>
      <p>
        Subject to compliance with these Terms, and based on the subscriptions
        subscribed, we authorize you to access and use the Site and its
        applicable services, and where applicable to download its content,
        exclusively for personal purposes. The license thus granted to you is
        strictly personal, non-exclusive, limited and revocable in the event of
        non-compliance with these Terms. In particular, we draw your attention
        to the fact that no assignment of intellectual property rights can be
        inferred from your use of the Site and the Services, including with
        respect to the items that we authorize you to download pursuant hereto.
      </p>
      <p>
        Except as specified below, you are strictly prohibited from performing
        or assisting a third party to do any or all of the following :
      </p>
      <ul>
        <li>
          Copying, storing, reproducing, transmitting, modifying, altering,
          disassembling, imitating, decompiling or disassembling the Site,
          including its services and content in any way, or creating derivative
          works from them; reproduction, etc.) for free or for a fee, all or
          part of the Site, the services or their content
        </li>
        <li>
          Altering all or part of the Website, services or their content or
          circumventing any technology used by L&D BP or its licensors to
          protect all content accessible through the Site and its services
        </li>
        <li>
          Bypassing security measures of the Site and access some or
          unauthorized functionality of the Site and Services
        </li>
        <li>
          Bypassing territorial restrictions applied to the Site and the
          services; or more generally
        </li>
        <li>
          Using the Services in a manner that violates this License Agreement or
          other Terms,
        </li>
      </ul>
      <p>
        As an exception to the above, a limited amount of content will be
        allowed (and specified as such, if any for a limited time) to be shared
        by the user on their personal social networks (Facebook, Twitter, etc.)
        and sites and personal internet pages (hereinafter the "Distribution
        Content"), L&D BP grants you a limited right to download, reproduce and
        distribute the Distribution Content on the Internet solely for the
        purpose of sharing and / or commenting on your social networks, sites
        and / or personal internet pages, as well as the following cumulative
        conditions: (i) the Distribution Content may not be modified under any
        circumstances, subject to the technical modifications necessary for its
        display and distribution through your computer systems and on the
        Internet (eg, a video format change or file size) that does not alter
        the content or quality of the Distribution Content, (ii) the
        Distribution Content may in no case may be shared, the distribution
        terms of the Distribution Content must comply with the additional terms
        that may be indicated on a case-by-case basis on the Site; Site, the
        Distribution Content must in no way be disseminated with any other
        content that you know to be false, inaccurate or misleading or that is
        or that encourages activity or conduct that is unlawful, harmful,
        threatening abusive, harassing, tortuous, defamatory, vulgar, obscene,
        pornographic, defamatory, disrespectful of the privacy of others,
        hateful, racist, or otherwise objectionable. This Distribution Content
        may contain gifs or cookies that allow us to collect information
        regarding the distribution and consumption of such content. For more
        information, please see our Privacy Policy.
      </p>
      <h3>6. Prohibited Uses of the Website and Services</h3>
      <h4>
        6.1. Breaching the integrity, security and operation of the Site and
        services
      </h4>
      <p>
        You may not upload, post, email, transmit or otherwise introduce on our
        platform any material or file containing computer viruses or any other
        computer code, files or programs designed to interrupt, damage, destroy
        or limit the functionality of any software or computer equipment or
        equipment directly or indirectly related to our services or our services
        themselves. You agree not to interfere with the servers or networks
        underlying or related to the Website or other Services or to violate the
        procedures, policies or regulations of the networks connected to our
        Website or our other services.
      </p>
      <p>
        You may not probe, scan or test the vulnerability of the Site or apps,
        violate security or authentication measures thereof, or attempt to trace
        or retrieve any information about any other user, visitor or members of
        the Site.
      </p>
      <p>
        You may not interfere or attempt to interfere with the access of any
        user, host or network, including, without limitation, the sending of a
        virus, overloading, flooding, spamming, or "mail bombing" of our
        services.
      </p>
      <p>
        You may not perform any action that imposes an unreasonable or
        disproportionate burden on the infrastructure of the Site, systems or
        networks, L&D BP' Apps, or systems or networks connected to the Site and
        apps, or that is likely to interfere with the proper functioning of the
        Site or the apps, with any transaction made on them, or with the use of
        any other person.
      </p>
      <p>
        You may not use "deep-link", "page-scrape", "robot", "spider", or other
        automatic device, program, algorithm or methodology or any similar
        manual process or equivalent, to access, acquire copy or monitor any
        part of the Site and the apps, and you may not attempt in any way to
        reproduce or bypass the navigation structure or layout of the websites,
        applications, or any content to obtain or attempt obtain material,
        documents or information by means not available for this purpose on
        websites or applications.
      </p>
      <p>
        You are also prohibited from accessing or attempting to access any
        Learning From Emotions server, or any of the services offered on or
        through the Site and apps, by hacking, "password mining", or using other
        illegitimate means.
      </p>
      <h4>6.2. Interactions on the Site and services</h4>
      <p>
        In general, you agree not to behave in an objectionable manner while
        using our services or to use the Services for any unlawful, immoral or
        harmful purpose.
      </p>
      <p>
        You are solely responsible for any messages, content and information
        published in it while logged in to Site since in its capacity as host of
        the service here considered, L&D BP cannot be responsible for the
        content published by members on the Site and on which L&D BP has no
        power of control and supervision.
      </p>
      <h3>7. Service Availability</h3>
      <p>
        While we strive to provide you with the best possible service, we do not
        promise that services will always be available and will meet your needs,
        expectations and charges; we cannot guarantee that the services will be
        flawless. If an error occurs in the services, thank you to report it to{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a> and we will
        try to correct the fault within a reasonable time. If the need arises,
        we can suspend access to services as we try to fix the problem. We will
        not be liable to you if the services are unavailable for a period of
        time.
      </p>
      <p>
        Your access to services may be occasionally restricted to allow for
        repairs, maintenance or the installation of new facilities or services.
        We will try to restore services as soon as reasonably possible. In the
        event that services are not available, our usual deadlines and dates of
        ordering and cancellation normally apply; thank you to inform us of the
        changes relating to your order via our{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>{' '}
      </p>
      <p>
        The content of the catalog is subject to change. We reserve the right to
        remove, add or modify functionalities without notice and without
        recourse.
      </p>
      <h3>8. Hyperlinks</h3>
      <h4>8.1. Links to other sites</h4>
      <p>
        We may provide links to other sites or access services. You acknowledge
        that access to these sites or services is at your sole discretion and
        that these links are present for your information only. We have not
        reviewed or endorsed any of these websites or services. We may not be
        held responsible in any way for: (a) availability, (b) personal data
        protection practices, (c) content, advertising, products, goods or other
        materials or resources available on or from these sites / services, or
        (d) the use of what others are doing with these sites and services. We
        are not responsible for any damage, loss or crime caused or allegedly
        caused by, or in connection with, the use or trust you place in these
        sites or services.
      </p>
      <h4>8.2. Link to the Website</h4>
      <p>
        You may link to our homepage provided that you do so in a manner that is
        fair and lawful and does not damage or benefit our reputation, but you
        do not have the right to establish a link in such a way as to suggest
        any form of association, approval or endorsement on our part that does
        not exist.
      </p>
      <p>
        You must not link to our Site on a website that is not owned by you. Our
        Site and our other services must not be framed or encapsulated in
        another site; you may not link to any part of our Site or our other
        services, unless you have L&D BP written permission to do so. We reserve
        the right to withdraw the authorization via a written notice in case of
        use that damages our image or our reputation. The Site from which you
        link to us must comply in all respects with the content standards set
        forth in these Terms, and in particular Article 6.2 above. If you wish
        to make another use of the information available on our website or our
        other services as indicated above, please send your request to{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>
      </p>

      <h3>9. Limitation of liability</h3>
      <p>
        We put all reasonable means at our disposal to ensure quality access to
        the Site and the security of the data that you transmit to us via the
        Site, but we assume no obligation of result in this respect.
      </p>
      <p>
        We do not guarantee the continued operation of the Site which may be
        interrupted for maintenance reasons.
      </p>
      <p>
        We cannot be held responsible for any other malfunction or failure of
        the network or servers or any other technical malfunction beyond our
        reasonable control that would prevent or impair access to all or part of
        the Site, and in case of force majeure as defined by the applicable
        regulations.
      </p>
      <p>
        In particular, we draw your attention to the limitations and constraints
        specific to the Internet and the impossibility of completely
        guaranteeing the security of data exchanges. It is your responsibility
        to take all the necessary measures to protect yourself against
        unauthorized intrusions into your information system, in particular by
        securing your internet connection with a password or by using the
        software appropriate anti-virus.
      </p>
      <p>
        Furthermore, you are informed that the quality of the operation of the
        Site and in particular response times may vary depending on the personal
        settings of your connection station and your Internet access service. We
        assume no liability in this regard.
      </p>
      <p>
        Consequently, we cannot under any circumstances see our liability
        engaged, directly or indirectly, because of the malfunctions of the
        Site, including any loss of data saved by your account, caused by one or
        more of the technical characteristics inherent to the Internet, or any
        computer hardware or software or software used by you for any reason.
      </p>
      <p>
        Similarly, no malfunction of the Site, including any loss of data saved
        by your account, we cannot be blamed if such a malfunction would result
        from misuse of the Site by you, or the inadequacy of the hardware and
        computer software that you use in relation to the technical
        specifications referred to above.
      </p>
      <p>
        Information made available on the Site is for informational purposes
        only and does not constitute in any case an invitation to contract,
        advice or a recommendation of any kind.
      </p>
      <p>
        Consequently, the use of the information and content available on all or
        part of the Site does not in any way engage our liability for any reason
        whatsoever.
      </p>
      <p>
        Within the limits of applicable mandatory legal provisions, under no
        circumstances, including but not limited in case of negligence, L&D BP
        or its affiliates, suppliers, customers, or suppliers (collectively, the
        "protected entities") may be held liable for any indirect, special,
        incidental, consecutive, exemplary or punitive damages arising out of,
        or related directly or indirectly to the use or inability to use the
        services or the content, materials and the functions associated with it,
        your provision of information via the services, or loss of business or
        loss of sales, or errors, viruses or bugs contained in the services,
        even if such protected entity has been informed of the possibility of
        such damage. Under no circumstances may protected entities be held
        responsible for or in connection with any content posted, transmitted,
        exchanged or received by or on behalf of a user or other person or
        through the services. In no event shall the overall liability of the
        entities protected by you for any damages, losses and consequences of
        events and actions (contractual or tort, including, but not limited to,
        negligence or otherwise) arising out of such Conditions or your use of
        the services will not exceed the sum of 50 (fithty) euros.
      </p>
      <p>
        Nothing in this clause is likely to limit the liability of L&D BP or
        protected entities in cases of fraud, gross negligence or bodily injury.
      </p>
      <h3>10. Medical warning</h3>
      <p>
        L&D BP is a provider of online and mobile subscriptions to access
        content related to emotions. We are not a medical device; we do not
        provide medical advice. All users with serious medical or psychological
        problems or requiring medical advice should consult their physician. L&D
        BP or contracted external service providers, are provided solely for
        your information and convenience.
      </p>
      <p>
        All advice or other items available in our services are intended solely
        for general information purposes. They are not meant to serve as a
        reference and substitute for professional medical advice based on your
        personal situation. The tips and other elements of the site are intended
        to help the relationship between you and your health care providers, but
        not to replace it. We cannot be held responsible for any action or
        action taken as a result of or after reading or hearing our advice and
        content or after using our services. In particular, to the extent
        permitted by law, we make no warranty as to the accuracy, completeness
        or relevance (for any purpose) of any notice or information published in
        the frame of our services.
      </p>
      <h3>11. Personal data</h3>
      <p>
        The personal data thus collected on the Site are processed in accordance
        with the amended law of June 20, 2018 on the protection of individuals
        with regard to the processing of personal data. You have a right to
        access and rectify data concerning you, subject to having a legitimate
        interest, and you may, subject to justifying overriding and legitimate
        reasons, oppose their treatment by contacting{' '}
        <a href="mailto:contact@lfeapp.com">contact@lfeapp.com</a>
      </p>
      <p>
        L&D BP reserves, in the event that the member would be responsible for
        an infringement of the legislation in force or an infringement of the
        rights of third parties, the right to provide, at the request of any
        legitimate authority (jurisdiction, administrative authority, police
        services), any information enabling or facilitating the identification
        of the offending member.
      </p>
      <p>
        For more information, please see our "Privacy Policy" which is available
        online and is an integral part of these Terms.
      </p>
      <h3>12. General provisions</h3>
      <h4>12.1. Transfer</h4>
      <p>
        L&D BP may transfer its rights and obligations under these Terms to any
        company, firm or person at any time if it does not materially affect
        your rights. You may not transfer your rights or obligations under these
        Terms to anyone other than L&D BP's prior written consent.
      </p>
      <h4>12.2. Guarantee and indemnities</h4>
      <p>
        You agree to defend, indemnify and support L&D BP and its directors,
        officers, members, investors, officers, employees and agents from any
        claim, liability claim, costs and expenses, including reasonable
        attorney fees that could arise in any way from your use of the Services,
        from your publication or transmission of any message, content,
        information, software or other communications through our services, or
        from your violation of the law or these terms. L&D BP reserves the
        right, at its own expense, to assume the exclusive defense and control
        of any matter otherwise likely to require compensation by you, and in
        this case, you agree to cooperate with L&D BP's defense of a such claim.
      </p>
      <h4>12.3. No waiver</h4>
      <p>
        If we delay the exercise or fail to exercise or enforce any right
        available to us under these Terms, such delay or failure does not
        constitute a waiver of such right or other rights under these terms.
      </p>
      <h4>12.4. Force majeure</h4>
      <p>
        We will not be liable to you for any lack of performance, unavailability
        or failure of services, or for any failure or delay on our part to
        comply with these conditions, in the event that this lack of
        performance, downtime or failure is due to a cause beyond our reasonable
        control.
      </p>
      <h4>12.5. Interpretation</h4>
      <p>
        In these Conditions, unless the context otherwise requires: (i) a phrase
        introduced by the words "including", "includes", "in particular", "for
        example" or any similar expression shall be construed as an illustration
        only and should not be interpreted as limiting the generality of all the
        preceding words; and (ii) the singular references include the plural and
        the masculine includes the feminine, and in each case vice versa.
      </p>
      <h4>12.6. Written communications</h4>
      <p>
        Applicable laws require that some of the information or communications
        we send you be in writing. When you use the Site and its services, you
        agree that all contracts, notices, information and other communications
        that we provide to you electronically have the same value as written
        communications.
      </p>
      <h4>12.7. Proof / Archiving Evidence</h4>
      <p>
        Computer data generated as a result of access and / or use of the Site
        (including, in particular, programs, connection data, files, records,
        operations and other items on computer or electronic media) (the "Data
        ") are likely to be kept and archived, within the limits provided for by
        law, by us within our computer system, under conditions that guarantee
        its integrity.
      </p>
      <p>
        The Data is authentic between you and us, and will be considered as
        evidence of communications between you and us.
      </p>
      <p>
        The Data constitutes literal proof and has the same probative force as a
        document that would be drawn up, received or kept in writing in paper
        form.
      </p>
      <p>
        Therefore, the Data may be validly produced as evidence and may be used
        against you in any claim or legal action with the same probative force
        as any document that would be drawn up, received or kept in writing in
        paper form, which you expressly recognize.
      </p>
      <p>
        We undertake not to contest the admissibility, opposability or probative
        value of the Data due to their electronic nature. You agree as well.
      </p>
      <p>
        You can have access to all Data on simple request addressed to us by
        simple mail or by e-mail.
      </p>
      <h4>12.8. Divisibility</h4>
      <p>
        If a court or a competent authority decides that any provision of these
        Terms is invalid, illegal or unenforceable to a certain extent, the
        provision will, to that extent only, be separate from other provisions,
        which will continue to be valid to the fullest extent permitted by law.
      </p>
      <h4>12.9. Entire Agreement</h4>
      <p>
        These Terms, as well as any additional terms and any other document
        expressly named in these terms represent the entire agreement between us
        and supersede all prior discussions, correspondence, negotiations,
        previous arrangements, documents to which they refer Each of us agrees
        that our sole remedy with respect to such representations and warranties
        as set forth in this Agreement (if done unintentionally or negligently)
        will be breach of contract. Nothing in this section limits or excludes
        liability for fraud or theft.
      </p>
      <h4>12.10. Applicable Law</h4>
      <p>These Terms are subject to French Law.</p>
      <p>
        Subject to the applicable mandatory legal provisions, the courts of
        Paris shall have exclusive jurisdiction to hear any dispute relating to
        the interpretation, application and performance of these Terms, as well
        as any dispute related to the use of the Site.
      </p>
      <p>These Terms take effect from their last update on 01/09/2019.</p>
    </Paper>
  </div>
)

TermsConditionsPage.propTypes = {
  classes: PropTypes.object // from enhancer (withStyles)
}

export default TermsConditionsPage
