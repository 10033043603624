import {
  compose,
  setDisplayName,
  withStateHandlers,
  withHandlers
} from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { withFirebase } from 'react-redux-firebase'
import styles from './BottomBar.styles'

export default compose(
  // Set component display name (more clear in dev/error tools)
  setDisplayName('EnhancedBottomBar'),
  // Add props.firebase (used in handlers)
  withFirebase,
  // Add state
  withStateHandlers(
    {
      value: ''
    },
    {
      setValue: state => value => ({ value: value })
    }
  ),
  // Add handlers as props
  withHandlers({
    handleChange: ({ setValue }) => (event, value) => {
      setValue(value)
    }
  }),
  // Add props.router (used in handlers)
  withRouter,
  // Add styles as classes prop
  withStyles(styles)
)
