import React from 'react'
import PropTypes from 'prop-types'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import SettingsIcon from '@material-ui/icons/Settings'
import HomeIcon from '@material-ui/icons/Home'
import InfoIcon from './Icons/InfoSvg'
import StatIcon from './Icons/StatSvg'
import { Link } from 'react-router-dom'
import {
  STATISTIC_PATH,
  HOME_PATH,
  INFORMATION_PATH,
  ACCOUNT_PATH
} from 'constants/paths'

export const BottomBar = ({ classes, value, handleChange }) => (
  <BottomNavigation
    value={value}
    showLabels
    className={classes.root}
    onChange={handleChange}>
    <BottomNavigationAction
      className={classes.buttonRoot}
      component={Link}
      to={HOME_PATH}
      value="home"
      icon={<HomeIcon />}
    />
    <BottomNavigationAction
      className={classes.buttonRoot}
      component={Link}
      to={STATISTIC_PATH}
      value="statistic"
      icon={<StatIcon />}
    />
    <BottomNavigationAction
      className={classes.buttonRoot}
      component={Link}
      to={INFORMATION_PATH}
      value="information"
      icon={<InfoIcon />}
    />
    <BottomNavigationAction
      className={classes.buttonRoot}
      component={Link}
      to={ACCOUNT_PATH}
      value="account"
      icon={<SettingsIcon />}
    />
  </BottomNavigation>
)

BottomBar.propTypes = {
  classes: PropTypes.object.isRequired // from enhancer (withStyles)
}

export default BottomBar
