import React from 'react'
import PropTypes from 'prop-types'
//import { BrowserRouter as Router } from 'react-router-dom'
import { Router } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import ThemeSettings from 'theme'
import { history } from 'utils/router'

const theme = createMuiTheme({
  ...ThemeSettings,
  palette: {
    secondary: {
      main: '#775EFF'
    }
  },
  typography: {
    useNextVariants: true
  }
})

const App = ({ routes, store }) => (
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <Router history={history}>{routes}</Router>
    </Provider>
  </MuiThemeProvider>
)

App.propTypes = {
  routes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
}

export default App
