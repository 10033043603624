import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/messaging'
//import { initializeMessaging } from 'utils/firebaseMessaging'
import { setAnalyticsUser } from 'utils/analytics'
import { setErrorUser } from '../utils/errorHandler'
import makeRootReducer from './reducers'
import {
  firebase as fbConfig,
  reduxFirebase as rrfConfig,
  env
} from '../config'
//import i18nMiddleware from './i18nMiddleware'
import i18n from './../utils/i18n'
import sentry from 'store/sentry'

export default (initialState = {}) => {
  // ======================================================
  // Redux + Firebase Config (react-redux-firebase & redux-firestore)
  // ======================================================
  const defaultRRFConfig = {
    userProfile: 'users', // root that user profiles are written to
    updateProfileOnLogin: false, // enable/disable updating of profile on login
    presence: 'presence', // list currently online users under "presence" path in RTDB
    sessions: null, // Skip storing of sessions
    enableLogging: false, // enable/disable Firebase Database Logging
    onAuthStateChanged: (auth, firebase, dispatch, history) => {
      if (auth) {
        // Set auth within analytics
        setAnalyticsUser(auth)
        setErrorUser(auth)
        // Initalize messaging with dispatch
        //initializeMessaging(dispatch)
        // i18n
        firebase.watchEvent('once', 'users/' + auth.uid).then(res => {
          if (res && res.data && res.data.language) {
            if (res.data.language === 'french') {
              i18n.changeLanguage('fr')
            } else if (res.data.language === 'english') {
              i18n.changeLanguage('en')
            }
          }
        })
        firebase.watchEvent('once', 'blockedUsers/' + auth.uid).then(res => {
          if (res && res.data && res.data === true) {
            firebase.logout()
          }
        })
      }
    }
  }

  // Combine default config with overrides if they exist (set within .firebaserc)
  const combinedConfig = rrfConfig
    ? { ...defaultRRFConfig, ...rrfConfig }
    : defaultRRFConfig

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = []

  if (env === 'local') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension())
    }
  }

  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [
    thunk.withExtraArgument(getFirebase),
    // This is where you add other middleware like redux-observable
    // i18nMiddleware
    sentry
  ]

  // ======================================================
  // Firebase Initialization
  // ======================================================
  firebase.initializeApp(fbConfig)

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    makeRootReducer(),
    initialState,
    compose(
      applyMiddleware(...middleware),
      reactReduxFirebase(firebase, combinedConfig),
      ...enhancers
    )
  )

  store.asyncReducers = {}

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  return store
}
