import React from "react";
import { Switch, Route } from "react-router-dom";
import CoreLayout from "../layouts/CoreLayout";
import AuthLayout from "../layouts/AuthLayout";
import Home from "./Home";
import LoginEnglishRoute from "./Login/English";
import LoginFrenchRoute from "./Login/French";
import SignupEnglishRoute from "./Signup/English";
import SignupFrenchRoute from "./Signup/French";
import PasswordLostRoute from "./PasswordLost";
import PasswordResetRoute from "./PasswordReset";
import AccountRoute from "./Account";
import NotFoundRoute from "./NotFound";
import InitRoute from "./Init";
import EmotionsListRoute from "./Emotions/components/EmotionsList";
import EmotionsAddRoute from "./Emotions/routes/EmotionAdd";
import EmotionsAddLittleHappinessRoute from "./Emotions/routes/EmotionAddLittleHappiness";
import EmotionsAddPlannedResponseRoute from "./Emotions/routes/EmotionAddPlannedResponse";
import EmotionsAddFullPathRoute from "./Emotions/routes/EmotionAddFullPath";
import EmotionTypeRoute from "./Emotions/routes/EmotionType";
import EmotionsEditRoute from "./Emotions/components/EmotionsEdit";
import EmotionsEditFullPathRoute from "./Emotions/routes/EmotionEditFullPath";
import EmotionsEditLittleHappinessRoute from "./Emotions/routes/EmotionEditLittleHappiness";
import EmotionsEditPlannedResponseRoute from "./Emotions/routes/EmotionEditPlannedResponse";
import EmotionsDetailRoute from "./Emotions/routes/EmotionDetail";
import StatisticRoute from "./Statistic";
import InformationRoute from "./Information";
import InformationEmotionDecisionRoute from "./Information/components/BasicsToKnow/EmotionDecision";
import InformationIdentifyEmotionRoute from "./Information/components/BasicsToKnow/IdentifyEmotion";
import InformationFacilitateBehaviorRoute from "./Information/components/BasicsToKnow/FacilitateBehavior";
import InformationBenefitGratitudeRoute from "./Information/components/BasicsToKnow/BenefitGratitude";
import InformationDictionaryEmotionRoute from "./Information/components/BasicsToKnow/DictionaryEmotion";
import InformationRejectionRoute from "./Information/components/FirstAidKit/Rejection";
import InformationFailureRoute from "./Information/components/FirstAidKit/Failure";
import InformationGuiltRoute from "./Information/components/FirstAidKit/Guilt";
import InformationLossAndTraumaRoute from "./Information/components/FirstAidKit/LossAndTrauma";
import InformationLowSelfEsteemRoute from "./Information/components/FirstAidKit/LowSelfEsteem";
import InformationRuminationRoute from "./Information/components/FirstAidKit/Rumination";
import InformationSolitudeRoute from "./Information/components/FirstAidKit/Solitude";
import PlanResponseRoute from "./PlanResponse";
import InvitationCodeRoute from "./InvitationCode";
import AdminRoute from "./Admin";
import PrivacyPolicyFrenchRoute from "./PrivacyPolicy/FR";
import PrivacyPolicyEnglishRoute from "./PrivacyPolicy/EN";
import LegalNoticeFrenchRoute from "./LegalNotice/FR";
import LegalNoticeEnglishRoute from "./LegalNotice/EN";
import TermsConditionsFrenchRoute from "./TermsConditions/FR";
import TermsConditionsEnglishRoute from "./TermsConditions/EN";

export default function createRoutes(store) {
  return (
    <CoreLayout>
      <Switch>
        {[
          SignupEnglishRoute,
          SignupFrenchRoute,
          LoginEnglishRoute,
          LoginFrenchRoute,
          PasswordLostRoute,
          PasswordResetRoute,
          InitRoute,
          PrivacyPolicyFrenchRoute,
          PrivacyPolicyEnglishRoute,
          LegalNoticeFrenchRoute,
          LegalNoticeEnglishRoute,
          TermsConditionsFrenchRoute,
          TermsConditionsEnglishRoute,
        ].map((settings, index) => (
          <Route exact key={`Route-${index}`} {...settings} />
        ))}
        }
        <AuthLayout>
          <Switch>
            <Route exact path={Home.path} component={Home.component} />
            {[
              AccountRoute,
              EmotionsListRoute,
              EmotionsAddRoute,
              EmotionTypeRoute,
              EmotionsAddLittleHappinessRoute,
              EmotionsAddPlannedResponseRoute,
              EmotionsAddFullPathRoute,
              EmotionsEditRoute,
              EmotionsEditFullPathRoute,
              EmotionsEditLittleHappinessRoute,
              EmotionsEditPlannedResponseRoute,
              EmotionsDetailRoute,
              StatisticRoute,
              InformationRoute,
              InformationEmotionDecisionRoute,
              InformationIdentifyEmotionRoute,
              InformationFacilitateBehaviorRoute,
              InformationBenefitGratitudeRoute,
              InformationDictionaryEmotionRoute,
              InformationRejectionRoute,
              InformationFailureRoute,
              InformationGuiltRoute,
              InformationLossAndTraumaRoute,
              InformationLowSelfEsteemRoute,
              InformationRuminationRoute,
              InformationSolitudeRoute,
              PlanResponseRoute,
              InvitationCodeRoute,
              AdminRoute,
            ].map((settings, index) => (
              <Route exact key={`Route-${index}`} {...settings} />
            ))}
            }
            <Route component={NotFoundRoute.component} />
          </Switch>
        </AuthLayout>
        <Route component={NotFoundRoute.component} />
      </Switch>
    </CoreLayout>
  );
}
